<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row text-muted">
        <div class="col-12 text-left">
          <p class="mb-0">
            <router-link class="text-muted" to="/">&copy; 2023 - Honduras</router-link>
          </p>
        </div>
        <div class="col-12 text-right"></div>
      </div>
    </div>
  </footer>
</template>

<script>
export default { name: 'AdminFooter' }
</script>
