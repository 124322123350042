<template>
  <div :class="classtemas">
    <div class="glide__track" data-glide-el="track">
      <ul class="glide__slides">
        <li class="glide__slide">
          <div class="gallery-block">
        <router-link to="/acuacultura">
          <div class="inner-box">
            <div class="image">
              <div class="hover-color-layer"></div>
              <router-link class="arrow ion-android-arrow-forward" to="/acuacultura"><img src="../../assets/institucional/img/icon/seta-direita.svg"></router-link>
              <img src="../../assets/institucional/img/galeria/acuacultura.jpg" alt="" />
              <div class="overlay-box">
                <div class="content">
                  <h3><router-link to="/acuacultura">Aquicultura</router-link></h3>
                </div>
              </div>
            </div>
          </div>
        </router-link>
          </div>
        </li>

        <li class="glide__slide">
          <div class="gallery-block">
        <router-link to="/acuariofilia">
          <div class="inner-box">
            <div class="image">
              <div class="hover-color-layer"></div>
              <router-link class="arrow ion-android-arrow-forward" to="/acuariofilia"><img src="../../assets/institucional/img/icon/seta-direita.svg"></router-link>
              <img src="../../assets/institucional/img/galeria/busca-acuariofilia.jpg" alt="" />
              <div class="overlay-box">
                <div class="content">
                  <h3><router-link to="/acuariofilia">Aquariofilia</router-link></h3>
                </div>
              </div>
            </div>
          </div>
        </router-link>
          </div>
        </li>

        <li class="glide__slide">
          <div class="gallery-block">
        <router-link to="/especies-marino-costeras">
          <div class="inner-box">
            <div class="image">
              <div class="hover-color-layer"></div>
              <router-link class="arrow ion-android-arrow-forward" to="/especies-marino-costeras"><img src="../../assets/institucional/img/icon/seta-direita.svg"></router-link>
              <img src="../../assets/institucional/img/galeria/busca-marino.jpg" alt="" />
              <div class="overlay-box">
                <div class="content">
                  <h3><router-link to="/especies-marino-costeras">Espécies marinho-costeiras</router-link></h3>
                </div>
              </div>
            </div>
          </div>
        </router-link>
          </div>
        </li>

        <li class="glide__slide">
          <div class="gallery-block">
        <router-link to="/ornamentales">
          <div class="inner-box">
            <div class="image">
              <div class="hover-color-layer"></div>
              <router-link class="arrow ion-android-arrow-forward" to="/ornamentales"><img src="../../assets/institucional/img/icon/seta-direita.svg"></router-link>
              <img src="../../assets/institucional/img/galeria/busca-ornamentales.jpg" alt="" />
              <div class="overlay-box">
                <div class="content">
                  <h3><router-link to="/ornamentales">Plantas ornamentais</router-link></h3>
                </div>
              </div>
            </div>
          </div>
        </router-link>
          </div>
        </li>

        <li class="glide__slide">
          <div class="gallery-block">
        <router-link to="/plantas-forrajeras">
          <div class="inner-box">
            <div class="image">
              <div class="hover-color-layer"></div>
              <router-link class="arrow ion-android-arrow-forward" to="/plantas-forrajeras"><img src="../../assets/institucional/img/icon/seta-direita.svg"></router-link>
              <img src="../../assets/institucional/img/galeria/busca-forrajeras.jpg" alt="" />
              <div class="overlay-box">
                <div class="content">
                  <h3><router-link to="/plantas-forrajeras">Plantas forrageiras</router-link></h3>
                </div>
              </div>
            </div>
          </div>
        </router-link>
          </div>
        </li>

        <li class="glide__slide">
          <div class="gallery-block">
        <router-link to="/uso-florestal">
          <div class="inner-box">
            <div class="image">
              <div class="hover-color-layer"></div>
              <router-link class="arrow ion-android-arrow-forward" to="/uso-florestal"><img src="../../assets/institucional/img/icon/seta-direita.svg"></router-link>
              <img src="../../assets/institucional/img/galeria/busca-forestal.jpg" alt="" />
              <div class="overlay-box">
                <div class="content">
                  <h3><router-link to="/uso-florestal">Uso florestal</router-link></h3>
                </div>
              </div>
            </div>
          </div>
        </router-link>
          </div>
        </li>
      </ul>
    </div>

    <div class="slider__bullets glide__bullets" data-glide-el="controls[nav]">
      <button class="slider__bullet glide__bullet active" data-glide-dir="=0"></button>
      <button class="slider__bullet glide__bullet" data-glide-dir="=1"></button>
      <button class="slider__bullet glide__bullet" data-glide-dir="=2"></button>
      <button class="slider__bullet glide__bullet" data-glide-dir="=3"></button>
      <button class="slider__bullet glide__bullet" data-glide-dir="=4"></button>
      <button class="slider__bullet glide__bullet" data-glide-dir="=5"></button>
      <button class="slider__bullet glide__bullet" data-glide-dir="=6"></button>
    </div>
  </div>
</template>

<script>
import '@glidejs/glide/dist/css/glide.core.min.css'
import '@glidejs/glide/dist/css/glide.theme.min.css'
import Glide from '@glidejs/glide'
export default {
  name: 'InstitucionalSliderTemasGlidePt',
  props: {
    classtemas: String
  },
  data () {
    return {
      glideTemas: {},
      options: {
        type: 'carousel',
        autoplay: 3500,
        perView: 4,
        gap: 10,
        breakpoints: {
          1440: {
            perView: 4
          },
          1200: {
            perView: 4
          },
          992: {
            perView: 3
          },
          768: {
            perView: 3
          },
          480: {
            perView: 2
          },
          0: {
            perView: 1
          }
        }
      }
    }
  },
  mounted () {
    this.mountGlide()
  },
  methods: {
    mountGlide: function () {
      this.glideTemas = new Glide(`.${this.classtemas}`, this.options)
      this.glideTemas.mount()
    }
  }
}
</script>
