export const ptBR = {
  message: {
    requiredField: 'Campo obrigatório',
    noRecords: 'Nenhum registro encontrado!',
    confirmDel: 'Tem certeza de que deseja excluir?',
    confirmApproval: 'Tem certeza de que deseja aprovar esta ocorrência?',
    noResultsFor: 'Nenhum resultado para'
  },
  login: {
    username: 'Usuário',
    password: 'Senha',
    login: 'Login',
    entrar: 'Entrar',
    descricao: 'Entre com seus dados de acesso no formulário abaixo:'
  },
  exportarFicha: {
    author: 'author',
    biodiversity_impact: 'biodiversity_impact',
    chemical_control: 'chemical_control',
    class: 'class',
    common_name: 'common_name',
    control_biological: 'control_biological',
    data_bibliography: 'data_bibliography',
    economic_impact: 'economic_impact',
    economic_use: 'economic_use',
    family: 'family',
    health_impact: 'health_impact',
    intro_data: 'intro_data',
    intro_description: 'intro_description',
    invasion_preferential_environments: 'invasion_preferential_environments',
    kingdom: 'kingdom',
    location: 'location',
    morphophysiology_description: 'morphophysiology_description',
    native_distribution_area: 'native_distribution_area',
    natural_environment: 'natural_environment',
    oorder: 'oorder',
    origin: 'origin',
    phyllum: 'phyllum',
    physical_control: 'physical_control',
    prevention: 'prevention',
    scientific_name: 'scientific_name',
    social_impact: 'social_impact',
    species_id: 'species_id',
    voc_cause_introduction: 'voc_cause_introduction',
    voc_diet: 'voc_diet',
    voc_dispersal_routes: 'voc_dispersal_routes',
    voc_dispersion_vectors: 'voc_dispersion_vectors',
    voc_form: 'voc_form',
    voc_introduction_type: 'voc_introduction_type',
    voc_reproduction: 'voc_reproduction',
    voc_spread: 'voc_spread',
    world_invasive_place: 'world_invasive_places'
  },
  label: {
    voltar: 'Voltar',
    outro: 'Outro',
    filtros: 'Filtros',
    acesseBaseDados: 'Acesse a base de dados',
    conhecaNossaRede: 'Rede Latino Americana',
    baseDadosNacional: 'Base de Dados Nacional',
    politicaDeDados: 'Política de dados',
    comiteCientifico: 'Comitê científico',
    colabore: 'Colabore',
    especiesMarinhas: 'Espécies marinhas',
    peixesAquario: 'Peixes de aquário',
    peixesAquicultura: 'Peixes de aquicultura',
    pescaDesportiva: 'Pesca desportiva',
    pets: 'Pets',
    plantasForrageiras: 'Plantas forrageiras',
    plantasOrnamentais: 'Plantas ornamentais',
    usoFlorestal: 'Uso florestal',
    tutorial: 'Tutorial',
    especies: 'Espécies',
    colaboradores: 'Colaboradorxs',
    referencias: 'Referências',
    projetos: 'Projetos',
    saibaMais: 'Saiba mais',
    contida: 'Contida',
    casual: 'Casual',
    estabelecida: 'Estabelecida',
    invasora: 'Invasora',
    exibirListagem: 'Exibir listagem',
    concluir: 'Concluir',
    fechar: 'Fechar',
    próximo: 'Próximo',
    anterior: 'Anterior',
    clickAqui: 'Clique aqui',
    numRegistrosEncontrados: 'Número de registros encontrados:',
    exibir10Reg: 'Exibir 10 registros',
    exibir25Reg: 'Exibir 25 registros',
    exibir50Reg: 'Exibir 50 registros',
    exibir100Reg: 'Exibir 100 registros',
    limpar: 'Limpar',
    buscar: 'Buscar',
    addColaborador: 'Adicionar colaborador/a',
    nome: 'Nome',
    sobrenome: 'Sobrenome',
    aprovarColaborador: 'Aprovar colaborador/a',
    salvarColaborador: 'Salvar colaborador/a',
    titulo: 'Título',
    cargo: 'Cargo',
    especialidade: 'Especialidade',
    instituicao: 'Instituição',
    endereco: 'Endereço',
    estado: 'Estado',
    cidade: 'Cidade',
    pais: 'País',
    email: 'E-mail',
    telefone: 'Telefone 1',
    telefone2: 'Telefone 2',
    websiteURL: 'Website URL',
    midiaSocial1: 'Mídia social 1',
    midiaSocial2: 'Mídia social 2',
    midiaSocial3: 'Mídia social 3',
    observacoesAdicionais: 'Observações adicionais',
    adicionar: 'Adicionar',
    editando: 'Editando',
    acoes: 'Ações',
    usuarixs: 'Usuárixs',
    adicionarUsuario: 'Adicionar usuárix',
    descricao: 'Descrição',
    ativo: 'Ativo',
    sim: 'Sim',
    nao: 'Não',
    salvarUsuarix: 'Salvar usuárix',
    senha: 'Senha',
    observacoes: 'Observações',
    grupos: 'Grupos',
    basico: 'Básico',
    administrator: 'Administrator',
    taxonomia: 'Taxonomia',
    nomeCientifico: 'Nome científico',
    adicionarEspecie: 'Adicionar espécie',
    genero: 'Gênero',
    familia: 'Família',
    ordem: 'Ordem',
    classe: 'Classe',
    phylumDivisao: 'Phylum / Divisão',
    reino: 'Reino',
    phylum: 'Phylum',
    origem: 'Origem',
    descricaoInvalida: 'Descrição inválida',
    selecioneFamilia: 'Por favor, selecione uma Família.',
    selecioneOrdem: 'Por favor, selecione uma Ordem.',
    selecioneClasse: 'Por favor, selecione uma Classe.',
    selecioneDivisao: 'Por favor, selecione um Phylum / Divisão.',
    selectioneReino: 'Por favor, selecione um Reino.',
    selectioneGenero: 'Por favor, selecione um Gênero.',
    addReferencia: 'Adicionar referência',
    autoresEX: 'Autorxs (ex. ZILLER, S. R.; DECHOUM, M. S.)',
    aprovarReferencia: 'Aprovar referência',
    salvarReferencia: 'Salvar referência',
    autorxsEX: 'Autorxs (ex. ZILLER, S. R.; DECHOUM, M. S.)',
    autores: 'Autorxs',
    local: 'Local (Ex. Salvador, BA  /  África do Sul: Cidade do Cabo)',
    localFiltro: 'Local',
    edicao: 'Edição (ex. 2 ed.)',
    editora: 'Editora',
    revista: 'Revista',
    anoPublicacao: 'Ano da publicação',
    meioDivulgacao: 'Meio de divulgação',
    volume: 'Volume (ex. v. 3)',
    numero: 'Número (ex. n. 28)',
    pagina: 'Páginas (ex. p. 25-34  /  245p.)',
    url: 'DOI / URL',
    palavraChave: 'Palavra-chave',
    anoPublic: 'Ano publicação',
    addProjeto: 'Adicionar projeto',
    aprovarProjeto: 'Aprovar Projeto',
    salvarProjeto: 'Salvar projeto',
    contato: 'Contato',
    areaGeografica: 'Área geográfica',
    objetivos: 'Objetivos',
    breveDescricao: 'Breve descrição',
    dataInicio: 'Data início',
    dataTermino: 'Data término',
    comentarios: 'Comentários',
    anoInicio: 'Ano de início',
    anoTermino: 'Ano de término',
    ocorrencias: 'Ocorrências',
    exportarDados: 'Exportar dados',
    addOcorrencia: 'Adicionar ocorrência',
    visualizarOcorrencia: 'Visualizar ocorrência',
    alterarOcorrencia: 'Alterar ocorrência',
    ocorrenciasVinculadas: 'Ocorrências vinculadas',
    areaProtegida: 'Área protegida',
    especie: 'Espécie',
    aprovarOcorrencia: 'Aprovar ocorrência',
    salvarOcorrencia: 'Salvar ocorrência',
    colaborador: 'Colaborador/a',
    municipio: 'Município',
    municipioSede: 'Município (sede)',
    municipioCentroide: 'Município (centróide)',
    referenciaLocal: 'Referência local',
    referencia: 'Referência',
    latitude: 'Latitude',
    longitude: 'Longitude',
    coordenadasCorrespondem: 'As coordenadas correspondem',
    distanciaAproximada: 'Distância aproximada',
    ambiente: 'Ambiente',
    baciaHidro: 'Bacia hidrográfica',
    descricaoInvasao: 'Descrição da invasão',
    abundancia: 'Abundância',
    situacaoPopulacional: 'Situação populacional',
    manejo: 'Manejo',
    dataEntrada: 'Data de entrada',
    dataObservacao: 'Data de observação (ex. 2020 ou 13/05/2020)',
    validado: 'Validado',
    especieMuseuHerbario: 'É um espécime de coleção zoológica ou herbário',
    selecioneTipo: 'Selecione o tipo',
    herbario: 'Herbário',
    museu: 'Coleção zoológica',
    numeroExemplaInstituicao: 'Número de exemplar (Instituição)',
    numeroExemplarColetor: 'Número de exemplar (Coletor)',
    selecioneOpcao: 'Selecione uma opção...',
    pointList1: 'A um ponto de ocorrência de um ou mais indivíduos da espécie',
    pointList2: 'Ao centro de um grupo de indivíduos da espécie',
    pointList3: 'A um ponto próximo da ocorrência, a uma distância aproximada de:',
    coordinatesSourceList1: 'A - Uma unidade política ou administrativa',
    coordinatesSourceList2: 'B - O local real da ocorrência',
    ultimoRegistroBaseDados: 'Último registros na base de dados',
    ultimosAcessos: 'Últimos acessos',
    fichaTecnicaEspecie: 'Ficha técnica das espécies',
    fichaTecnicaEspecieMMA: 'Ficha técnica das espécies MMA versão 1',
    fichaTecnicaEspecieMMA2: 'Ficha técnica das espécies MMA versão 2',
    invasoras: 'Invasora',
    contidasBrasil: 'Contida',
    ausentesBrasil: 'Ausente',
    deficiencia: 'Dados insuficientes (DD)',
    formatosDisponiveisExportacao: 'Formatos disponíveis para exportação:',
    biologiaEcologia: 'Biologia e ecologia',
    usoEconomico: 'Uso econômico',
    usoEconomicoDescricao: 'Uso econômico - descrição',
    invasaoBiologica: 'Invasão biológica',
    impactos: 'Impactos',
    habitat: 'Hábitat',
    nomeCientificoSinonimos: 'Nome científico + sinônimos',
    nomesPopulares: 'Nomes populares',
    reproducao: 'Reprodução',
    dispersao: 'Dispersão',
    dieta: 'Dieta',
    formaBiologica: 'Forma biológica',
    ambientesPreferenciaisInvasao: 'Ambientes mais suscetíveis à invasão',
    tipoIntroducao: 'Tipo de introdução',
    causaIntroducao: 'Causa de introdução',
    localIntroducao: 'Local de introdução',
    anoIntroducao: 'Ano da introdução',
    marinho: 'Marinho',
    dulcicola: 'Água doce',
    terrestre: 'Terrestre',
    validarEspecie: 'Validar espécie',
    salvarEspecie: 'Salvar espécie',
    data: 'Data',
    confiabilidade: 'Confiabilidade',
    resultadosAnalisesRisco: 'Resultados de análises de risco',
    risco: 'Risco',
    areaOrigem: 'Área de origem',
    areaDistribuicaoNatural: 'Área de distribuição natural',
    ambientesNaturais: 'Ambientes naturais',
    descricaoEspecie: 'Descrição da espécie',
    ambientesPreferenciaisInvasaoDescricao: 'Ambientes mais suscetíveis à invasão - descrição',
    outrosLocaisOndeEspecieInvasora: 'Outros locais onde a espécie é invasora',
    impactosEcologicos: 'Impactos ecológicos',
    impactosEconomicos: 'Impactos econômicos',
    impactosSociais: 'Impactos sociais',
    impactosSaude: 'Impactos à saúde',
    categoriaEICAT: 'Categoria EICAT',
    mecanismosImpactoEICAT: 'Mecanismos de impacto EICAT',
    nivelConfiancaAvaliacaoEICAT: 'Nível de confiança da avaliação EICAT',
    referenciaEICAT: 'Referência EICAT',
    dataEICAT: 'Data EICAT (ex. 2021)',
    categoriaSEICAT: 'Categoria SEICAT',
    mecanismosImpactoSEICAT: 'Mecanismos de impacto SEICAT',
    nivelConfiancaAvaliacaoSEICAT: 'Nível de confiança da avaliação SEICAT',
    referenciaSEICAT: 'Referência SEICAT',
    dataSEICAT: 'Data SEICAT (ex. 2021)',
    digitarSomenteAno: 'Digitar somente o ano - 2021',
    viasCDBCategorias: 'Vias/vetores CDB - categorias',
    viasIntroducao: 'Vias de introdução e dispersão',
    viasVetoresCDBSubcategorias: 'Vias/vetores CDB - subcategorias',
    vetoresIntroducao: 'Vetores de introdução e dispersão',
    introducao: 'Introdução',
    adicionarIntroducao: 'Adicionar introdução',
    ano: 'Ano',
    descricaoIntroducao: 'Descrição da introdução',
    salvar: 'Salvar',
    cancelar: 'Cancelar',
    excluir: 'Excluir',
    medidasPreventivas: 'Medidas preventivas',
    medidasPreventivasMMA: 'Medidas preventivas MMA',
    medidasDeteccaoPrecoce: 'Medidas de detecção precoce',
    controleMecanico: 'Controle mecânico',
    controleQuimico: 'Controle químico',
    controleBiologico: 'Controle biológico',
    distribuicaoPais: 'Distribuição no país',
    vejaMapaOcorrencias: 'Veja o mapa de ocorrências',
    ecologiaUso: 'Ecologia e uso',
    introducaoDispersao: 'Introdução e dispersão',
    analiseRisco: 'Análise de risco',
    nomeComum: 'Nome comum',
    foto: 'Foto',
    credito: 'Crédito',
    sinonimos: 'Sinônimos',
    autor: 'Autor/a',
    subespecie: 'Subespécie',
    autorSubespecie: 'Autor/a da subespécie',
    pendenteAnalise: 'Pendente de análise',
    cancelarVoltar: 'Cancelar / Voltar',
    versaoAnterior: 'Versão anterior',
    versaoAtual: 'Versão atual',
    colaboradorxs: 'Colaboradorxs',
    consultaTaxonomiaListaCompletaEspecies: 'Taxonomia - Lista completa de espécies',
    consultaEspeciesOrigemAmbientes: 'Espécies, origem, ambientes de invasão e impactos',
    consultaEspeciesManejo: 'Espécies e alternativas de manejo',
    consultaParaGerarFichaTecnica: 'Ficha técnica da espécie sem ocorrências',
    consultaEspeciesOcorrencias: 'Espécies e locais de ocorrência, com coordenadas',
    exportarDadosConsultas: 'Exportar consultas',
    ocorrenciasApp: 'Ocorrências App',
    dataCadastro: 'Data de cadastro',
    criadoPor: 'Criado por',
    nivelCerteza: 'Nível de certeza',
    byApp: 'Por aplicativo móvel',
    verDados: 'Ver dados',
    appuser: 'App user',
    profissao: 'Profissão',
    colobadorJaCadastrado: 'Colaborador já cadastrado',
    especialidadeApp: 'Especialidade App',
    cep: 'CEP',
    categoriaCDB: 'Categoria CDB',
    subCategoriaCDB: 'Subcategoria CDB',
    mensagemEnviada: 'Mensagem enviada com sucesso.'
  },
  home: {
    destaque1: 'Bem-vindo ao Banco de Dados Nacional de Espécies Exóticas Invasoras!',
    destaqueDescricao1: 'Este sistema de banco de dados recebe contribuições de pessoas comprometidas com a conservação da biodiversidade. Convidamos você a visitar nossa página!',
    destaque2: 'Desde 2005 documentando espécies exóticas invasoras e compartilhando as informações online!',
    destaqueDescricao2: 'Este sistema de banco de dados foi desenvolvido e mantido atualizado com recursos nacionais e internacionais e conta com um Comitê Científico de apoio.',
    destaque3: 'O que inclui este sistema de informação?',
    destaqueDescricao3: 'Gerenciamos quatro bancos de dados interligados sobre espécies, referências, colaboradores e projetos.',
    destaque4: 'Como funciona?',
    destaqueDescricao4: 'Você pode consultar sobre espécies exóticas invasoras e potencialmente invasoras, especialistas, projetos e bibliografia na República de Honduras.',
    titulo: 'Consulte as espécies, filtre e exporte dados',
    conteudo: 'Aqui você encontra informações sobre a área de origem das espécies exóticas invasoras, características ecológicas e biológicas, ambientes invadidos, onde são invasoras no mundo e no Costa Rica, incluindo um mapa de ocorrências já registradas, histórico de introdução e uso, vetores e vias de introdução e dispersão, informações sobre manejo e referências. Encontra filtros para seleção de informações e pode exportar os dados de interesse para diferentes formatos.',
    conteudoEspecies: 'Lista de espécies, características ecológicas, área de origem, onde é invasora mundo afora, vias e vetores de introdução, análise de risco, usos, opções de manejo, ocorrência no Costa Rica, referências.',
    conteudoColaboradores: 'Cadastro de pessoas que já enviaram dados para a base de dados. Se você precisar falar com um especialista ou saber mais sobre uma ocorrência, busque contatos aqui.',
    conteudoReferencias: 'Listagem completa de referências utilizadas como base para o preenchimento das informações sobre espécies exóticas invasoras na base de dados.',
    conteudoProjetos: 'Projetos de pesquisa e de manejo desenvolvidos para espécies exóticas invasoras no Costa Rica. Se você tem um projeto em andamento, escreva-nos para que possamos fazer a inclusão.',
    titulo1: 'Conheça as espécies exóticas invasoras.',
    conteudo1: 'Aqui você encontra listagens de espécies exóticas invasoras organizadas por grupos que indicam o motivo ou a via pela qual foram introduzidas em Honduras. As páginas incluem uma explicação sobre cada grupo e links para fontes adicionais de informação.'
  },
  areaParceiros: {
    titulo: 'Rede de Bases de Dados de Espécies Exóticas Invasoras:',
    argentina: 'Argentina',
    paraguai: 'Paraguai',
    uruguai: 'Uruguai'
  },
  redeBaseDeDados: {
    cabecalhoTitulo: 'Rede Latino Americana e do Caribe',
    paragrafo1: 'Esta rede de bases de dados tem origem no ano de 2005, como parte da rede temática sobre espécies exóticas invasoras I3N (IABIN Invasives Information Network), que era parte da Rede Inter-Americana de Informação sobre Biodiversidade (IABIN), estabelecida pelos governos dos países das Américas no ano de 2001.',
    paragrafo2: 'Cada país tinha um/a Líder na rede, cuja função era desenvolver uma base de dados nacional a fim de compartilhar informação sobre espécies exóticas invasoras.',
    paragrafo2_1: 'As pessoas na liderança das bases de dados nacionais são:',
    marcador1: 'Argentina: Dr. Sergio M. Zalba, Professor, Universidad Nacional del Sur, Bahía Blanca (desde 2003);',
    marcador2: 'Brasil: Dra. Sílvia R. Ziller, Fundadora e Diretora Executiva do Instituto Hórus de Desenvolvimento e Conservação Ambiental (desde 2004);',
    marcador3: 'Chile: Dr. Aníbal Pauchard, Professor, Facultad de Ciencias Forestales, Universidad de Concepción;',
    marcador4: 'Costa Rica: PhD. Eduardo Chacón-Madrigal, Professor da Universidade da Costa Rica. Pesquisadora do Centro de Pesquisas em Biodiversidade e Biologia Tropical. Co-coordenador da Flora Invasiva e Naturalizada da América Central;',
    marcador5: 'Equador: Dr. Diego Inclán, Diretor Executivo do Instituto Nacional de Biodiversidade (desde 2021) e Francisco José Prieto Albuja;',
    marcador5_1: 'Honduras: Dra Lilian Ferrufino, Universidad Nacional Autónoma de Honduras (desde 2023).',
    marcador5_2: 'Jamaica: Dra. Suzanne Davis, Clearing-House Mechanism, Institute of Jamaica (desde 2007).',
    marcador5_3: 'Paraguai: Hugo Fernando del Castillo, Guyra Paraguay Conservação de Aves (desde 2006);',
    marcador5_4: 'Uruguai: Dr. Ernesto Brugnoli, Professor, Universidad de la República, Montevideo (desde 2005), e o M.Sc. Marcelo Ituruburu, Coordenador do Comitê de Espécies Exóticas Invasoras Ministério do Meio Ambiente (desde 2018);',
    paragrafo3: 'A IABIN foi financiada por um projeto do Banco Mundial (GEF) entre os anos de 2004 e 2011, então extinta. Apesar do término da iniciativa, alguns dos Líderes da rede seguem mantendo as bases de dados nacionais (Brasil, Argentina, Uruguai, Paraguai e Jamaica).',
    paragrafo4: 'No ano de 2021, recebemos um recurso da Secretaria da Convenção sobre Diversidade Biológica, como parte da iniciativa BioBridge, para revitalizar e atualizar as bases de dados da Argentina, do Brasil, do Paraguai e do Uruguai. A interface foi atualizada e novas funcionalidades foram embutidas nas bases de dados.',
    paragrafo5: 'Os desenvolvedores da Base de Dados são:',
    marcador6: 'O programador João Scucato, de Curitiba – PR, que desenvolveu a primeira versão em Microsoft Access, 2004-2005;',
    marcador7: 'O programador Alejandro Moreno, da Argentina, está envolvido com a manutenção da rede desde 2006. Manteve cópias dos arquivos e resgatou conteúdos do Paraguai e Uruguai para esta nova edição, além de desenvolver a',
    marcador7_1: 'Plataforma Comum',
    marcador7_2: 'dos países da rede;',
    marcador8: 'O designer Rafael Moura e pelo Programador Thiago Lôbo, vinculados à',
    marcador8_1: 'Plataforma Vibbra',
    marcador8_2: 'em Florianópolis – SC, Brasil; ',
    marcador9: 'O programador Leonardo Rotondano, responsável pelo desenvolvimento do aplicativo para telefone celular usado para aporte de ocorrências de espécies exóticas invasoras.',
    paragrafo6: 'Esperamos voltar a ampliar a rede para mais países das Américas a fim de que mais dados sobre espécies exóticas invasoras fiquem disponíveis para fins de conhecimento público, gestão governamental e manejo aplicado.',
    paragrafo7: 'Rede de Bases de Dados de Espécies Exóticas Invasoras:',
    paragrafo8: '',
    paragrafo9: ''
  },
  baseDadosNacional: {
    cabecalhoTitulo: 'Base de dados nacional',
    titulo: 'A base de Dados Nacional de Espécies Exóticas Invasoras',
    paragrafo1: 'Esta plataforma de informação foi desenvolvida no ano de 2004 através de uma parceria do Instituto Hórus com a Universidad Nacional del Sur em Bahía Blanca, na Argentina (Dr. Sergio Zalba), como parte da rede de informação sobre espécies exóticas invasoras, então denominada rede I3N (IABIN Invasives Information Network). A rede era, na época, coordenada por Andrea Grosse, uma Bióloga que foi muito dedicada à rede e viabilizou o desenvolvimento da estrutura da base de dados e de inúmeros produtos adicionais, como análises de risco, manuais e cursos de treinamento sobre a temática. Outra pessoa que foi fundamental para o crescimento da rede foi a Dra. Annie Simpson do USGS, então responsável pela Rede Global de Informação sobre Espécies Invasoras (GISIN), cujo objetivo era estabelecer padrões de informação para espécies exóticas invasoras a fim de facilitar a comunicação entre bases de dados globais e em diversos países. Os recursos para o desenvolvimento da base de dados foram aportados pelo Banco Mundial (GEF) para um projeto coordenado pelo Serviço Geológico dos Estados Unidos (USGS) que tinha por objetivo o estabelecimento da IABIN – Rede InterAmericana de Informação sobre Biodiversidade.',
    paragrafo1_1: '',
    paragrafo2: 'A Rede I3N existiu entre os anos de 2001 e 2011, quando o projeto terminou. Nesse período, a estrutura da base de dados foi distribuída a 21 países das Américas através de cursos de treinamento para a gestão do tema em nível nacional e da própria base de dados: Argentina, Brasil, Uruguai, Chile, Paraguai, Bolívia, Peru, Equador, Colômbia, Venezuela, Suriname, República Dominicana, Bahamas, Jamaica, St. Lucia, Panamá, Costa Rica, Honduras, El Salvador, Nicarágua e Guatemala.',
    paragrafo3: 'Infelizmente, a maior parte das bases de dados nacionais se perdeu ao longo do tempo. As bases de dados que foram mantidas desde o início são a da Argentina (Universidad Nacional del Sur, Administrador Dr. Sergio Zalba); do Brasil (Instituto Hórus, Dra. Sílvia Ziller e Dra. Michele de Sá Dechoum), do Uruguai (Universidad de la República em Montevidéu, Dr. Ernesto Brugnoli e Comitê de Espécies Exóticas Invasoras Ministério do Meio Ambiente, M.Sc. Marcelo Iturburu) e da Jamaica (Institute of Jamaica, Dra. Suzanne Davis). A cooperação entre essas bases de dados nunca foi interrompida pelxs administradorxs.',
    paragrafo4: 'No ano de 2011 a base de dados foi atualizada, passando do formato original em Microsoft Access para o formato de software livre, em MySQL. Nessa ocasião foram feitas inúmeras correções e melhorias a partir de relatos de experiência dos usuários em diversos países. A nova versão foi então distribuída aos países que faziam parte da rede.',
    paragrafo5: 'Dez anos depois, em 2021, a Iniciativa BioBridge da Secretaria da Convenção Internacional sobre Diversidade Biológica em Montreal, Canadá, proveu recursos para uma nova atualização para a Argentina, o Brasil, o Uruguai e o Paraguai e, em 2022, para estabelecer bases de dados na Costa Rica, Honduras e Chile, assim como atualizar a base de dados da Jamaica.. Novamente foram realizadas diversas melhorias visando facilitar o aporte, a edição e a exportação de dados pelxs usuárixs. Esperamos que esta versão seja mais acessível e mais amigável, e que a Base de Dados Nacional possa prover base para a gestão pública, para manejo em campo e leve à elaboração de muitos artigos técnicos e científicos. Também esperamos que o conhecimento sobre espécies exóticas invasoras estimule o uso de espécies nativas, especialmente, a fim de salvaguardar a diversidade biológica e a resiliência dos ecossistemas naturais do Brasil e das Américas.',
    paragrafo6: 'Agradecemos às pessoas que têm colaborado com a Base de Dados Nacional, tanto pelo aporte de dados e ajuda na validação de espécies e ocorrências, como por todo tipo de suporte que é requerido. A riqueza de dados disponível é resultado, sem dúvida, de um significativo esforço conjunto.',
    paragrafo7: ''
  },
  politicaDeDados: {
    cabecalhoTitulo: 'Política de dados',
    titulo: 'Conceitos e fontes de referência',
    criterio: 'Critérios para inclusão de espécies',
    avaliacao: 'Avaliação e revisão de dados',
    validacao: 'Validação de dados',
    paragrafo1: 'Para fins desta base de dados define-se, em acordo com a Convenção Internacional sobre Diversidade Biológica:',
    marcador1: 'espécie nativa: aquela que se encontra na área de distribuição geográfica onde evoluiu e forma parte de uma comunidade biótica em equilíbrio;',
    marcador2: 'espécie exótica: aquela que se encontra fora de sua área de distribuição natural, passada ou presente;',
    marcador3: 'espécie exótica invasora: aquela espécie exótica que ameaça hábitats, ecossistemas ou outras espécies, causando impactos e alterações em ambientes naturais.',
    paragrafo2: 'A base de dados nacional de espécies exóticas invasoras de Honduras inclui apenas espécies exóticas invasoras que já se encontram presentes no país. As espécies registradas encontram-se sempre relacionadas a pelo menos uma ocorrência geográfica em Honduras.',
    paragrafo3: 'As espécies que constam na base de dados não devem ser consideradas como invasoras de forma homogênea em todo o país, pois sempre há um ou mais ambientes preferenciais para invasão onde cada espécie expressa maior agressividade. Por isso, para cada espécie estão sempre indicados os ambientes e também os locais de ocorrência. Não são incluídas na base de dados espécies sem ao menos uma ocorrência confiável conhecida.',
    paragrafo4: 'O sistema de classificação de ambientes terrestres utilizado é o Manual de Classificação da Vegetação Brasileira do IBGE, edição de 2012. Os termos utilizados para ambientes marinhos e de água doce foram definidos com especialistas nas respectivas áreas. O vocabulário controlado para os campos em comum entre as bases de dados foi adotado da Base de Dados Global de Espécies Invasoras do Grupo Especialista em Espécies Invasoras vinculado à IUCN',
    paragrafo5_1: 'As referências taxonômicas principais são o Missouri Botanical Garden ',
    paragrafo5_2: 'The Plant List ',
    paragrafo5_3: 'e o Integrated Taxonomic Information System dos Estados Unidos - ITIS. ',
    paragrafo5_4: 'Outras bases de dados são comumente consultadas para complementação de informações ou questões taxonômicas de grupos específicos, como World Register of Marine Species',
    paragrafo5_5: 'além da Base de Dados Global de Espécies Invasoras',
    paragrafo5_6: 'e do Compêndio de Espécies Invasoras da CABI.',
    paragrafo6: 'Em cada ponto de ocorrência, cada espécie é enquadrada em uma das seguintes categorias:',
    marcador4: ': quando sua presença está restrita a estruturas de uso antrópico, como laboratórios ou áreas de cultivo que não permitam o escape de indivíduos para ambientes naturais.',
    marcador5: ': quando a espécie se encontra no ambiente natural, em geral plantada ou cultivada, ou recém-introduzida, ainda sem evidências de reprodução ou dispersão.',
    marcador6: ': quando a espécie se encontra no ambiente natural já com uma população viável, reproduzindo-se, porém apenas localmente, ainda sem dispersão ampla para outras áreas.',
    marcador7: ': quando a espécie se encontra no ambiente natural, já em reprodução e em processo de expansão, seja inicial ou avançado, para outras áreas além do ponto onde foi introduzida.',
    paragrafo7: 'Assim sendo, é importante que fique claro que uma espécie pode ser invasora num local, ainda estar em fase de latência (adaptação) em outro, e não ser invasora em ainda outro ambiente.',
    paragrafo8: 'Cada informação de ocorrência recebida fica sempre vinculada à fonte, ou seja, à pessoa que a forneceu e/ou a um trabalho científico, incluindo um ou mais autores. Outros dados são referenciados nos campos descritivos à medida do possível. Se houver dúvidas, questionamentos ou necessidades de mais informação sobre algum dado, a fonte pode ser indicada ou consultada em busca de esclarecimentos. Esse vínculo tem principalmente o intuito de preservar a fonte da informação, reconhecer a autoria e valorizar o trabalho e o apoio dos colaboradores.',
    paragrafo8_en: '',
    paragrafo9_1: 'Se você tiver dados sobre espécies exóticas invasoras para contribuir com a base de dados, por favor utilize a planilha disponível no site e envie para ',
    paragrafo9_2: ' ou',
    paragrafo9_3: ' entre em contato conosco',
    paragrafo10: 'Se tiver dúvidas ou questionamentos, por favor escreva para ',
    paragrafo11: 'Os critérios básicos para a inclusão de espécies na base de dados são: (a) que estejam presentes no Brasil e haja pelo menos um ou alguns registros de ocorrência confiáveis; (b) tenham histórico de invasão registrado no próprio país ou em outra parte do mundo, geralmente em condições climáticas que propiciam a adaptação da espécie a algum tipo climático brasileiro. Pode haver exceções para espécies exóticas invasoras que ainda não estejam expressando comportamento invasor, mas sejam consideradas de alto risco para a diversidade biológica brasileira e ocorram no país de forma contida (em cativeiro, aquários, laboratórios, etc.) ou em países limítrofes ou com os quais o Brasil mantém relações comerciais importantes, com risco iminente de chegada. Nenhuma espécie é incluída sem que haja pelo menos um ou mais registros validados de ocorrência.',
    paragrafo12: 'A base de dados contempla espécies exóticas invasoras de todos os grupos biológicos que tenham potencial de impacto sobre a diversidade biológica e/ou sobre ambientes naturais. Muitas vezes as mesmas espécies também geram impacto sobre a economia, sobre valores sociais ou culturais ou sobre a saúde humana ou animal, porém o foco desta base de dados são as espécies que têm impacto sobre sistemas naturais e sobre a diversidade biológica.',
    paragrafo13: 'Uma vez que uma espécie é registrada na base de dados, os dados complementares são incluídos. À medida que há mais informação disponível, são incorporadas novas informações e, principalmente, novas ocorrências da espécie no país. Esses dados são obtidos de publicações técnicas e científicas, de colaboradores diretos que realizam trabalho de campo, de gestores e funcionários de unidades de conservação ou por observação e coleta de pontos diretamente no campo. A inclusão de coordenadas geográficas é obrigatória para todos os registros.',
    paragrafo14: 'Os dados são revisados continuamente à medida que mais informações sobre uma espécie é publicada ou comunicada diretamente por especialistas. Pode haver questionamento sobre espécies inseridas na base de dados em função de novas informações, caso em que é buscado apoio de especialistas no grupo biológico em questão para decidir se a espécie deve permanecer ou ser removida. Algumas espécies incluídas nos primeiros anos de trabalho da base de dados foram posteriormente removidas porque não havia dados suficientes para atender os critérios de inclusão ou faltavam evidências de invasão biológica. Isso pode ocorrer, por exemplo, quando se reconhece uma espécie exótica que é observada em algum local, porém não há evidências de invasão ao longo do tempo, nem há dados que confirmem o potencial de invasão no Brasil ou em outras partes do mundo. Nesses casos, os dados das espécies removidas são armazenados à parte e ficam numa lista de espera, pois se essa condição mudar, os dados podem ser facilmente restaurados.',
    paragrafo15: 'A validação dos dados inseridos na base de dados por colaboradores é feita com base na confiabilidade das informações, especialmente com relação à identificação da espécie e ao local de ocorrência.',
    paragrafo16: 'Validação taxonômica',
    marcador8: 'A pessoa que aportou os dados é especialista no grupo biológico em questão ou tem experiência/conhecimento equivalente?',
    marcador9: 'A ocorrência está documentada mediante uma fotografía que permite a identificação confiável do organismo em questão?',
    marcador10: 'A ocorrência está documentada em uma publicação indexada e com avaliação de pares ou num documento técnico elaborado ou revisado por especialistas?',
    marcador11: 'A informação corresponde a um exemplar de herbário ou a espécime depositado numa coleção de museu?',
    marcador12: 'A ocorrência corresponde a uma espécie que já estava registrada para o país?',
    marcador13: 'O grupo a que pertence a espécie tem taxonomia estável e bem conhecida?',
    marcador14: 'As características da espécie permitem a identificação com facilidade?',
    paragrafo17: 'A resposta positiva a pelo menos uma das perguntas 1, 2, 3 ou 4 recomenda a validação do dado. Se essas quatro perguntas tiverem respostas negativas, o dado somente será validado se as três perguntas subsequentes tiverem respostas positivas (5, 6 e 7).',
    paragrafo18: 'Validação geográfica',
    marcador15: 'O dado inclui coordenadas geográficas? São consideradas coordenadas tomadas diretamente no local da observação, como outras que correspondem à referência geográfica próxima (caso indicada).',
    marcador16: 'O dado inclui uma descrição do local que permite sua identificação confiável?',
    marcador17: 'A ocorrência está documentada mediante uma fotografía que permite a identificação confiável do local?',
    marcador18: 'As coordenadas geográficas coincidem com a localização da área descrita e/ou fotografiada?',
    marcador19: 'A espécie já está citada para o país e, nesse caso, o local está incluído na área de distribuição conhecida da espécie ou num local compatível com os ambientes onde a espécie já ocorre (ambientes preferenciais de invasão conhecidos)?',
    marcador20: 'As condições ecológicas do local, inferidas em função da localização indicada, são compatíveis com os requerimentos gerais de nicho da espécie?',
    paragrafo19: 'O dado será validado do ponto de vista da localização geográfica se:',
    marcador21: 'a resposta a uma das perguntas 1, 2 e/ou 3, e 4 é positiva; ',
    marcador22: 'a resposta à pergunta 1 é positiva ou negativa, uma resposta negativa às perguntas 2 e 3, mas as respostas às perguntas 5 e 6 são positivas. ',
    paragrafo20: 'O dado de localização não será validado se: ',
    marcador23: 'a resposta à pergunta 1 e às perguntas 2 e/ou 3 são positivas, mas a resposta à pergunta 4 é negativa;  ',
    marcador24: 'a resposta à pergunta 1 é positiva ou negativa e a resposta às perguntas 5 e 6 é positiva somente para uma ou para nenhuma das perguntas 5 e 6.',
    paragrafo21: 'Finalmente, o dado completo é validado quando aprovado tanto na análise de identificação como na de localização.'
  },
  comiteCientifico: {
    cabecalhoTitulo: 'Comitê científico',
    paragrafo1: 'A Base de Dados Nacional de Espécies Exóticas Invasoras conta com o apoio de um grupo de especialistas em diversas áreas do conhecimento para realizar a validação de dados recebidos. Nesse grupo estão taxonomistas ou especialistas em grupos específicos de espécies, assim como profissionais com experiência de campo cujo conhecimento é de alta relevância para apoiar a verificação de informações.',
    paragrafo2: 'A partir de 2023 essa contribuição passou a ser formalizada, sendo algumas pessoas convidadas a compor um Comitê Científico para apoio à validação de dados e à gestão da Base de Dados Nacional de Espécies Exóticas Invasoras. O papel do Comitê Científico é prover apoio para a validação de dados, aportar dados de interesse para a base de dados e ajudar a construir e manter a rede de colaboradores para viabilizar a atualização das informações e aumentar o número de aportes de dados.',
    paragrafo3: 'O Comitê Científico é nomeado para períodos de três anos, podendo ser renovadas as nomeações ou substituídas as pessoas nomeadas. O primeiro Comitê Científico foi formado ao longo do ano de 2023, por:',
    marcador1: 'Lilian Ferrufino-Acosta, Bióloga M.Sc., Dr.rer.nat, Docente da Universidade Nacional Autônoma de Honduras, Administradora da Base de Dados;',
    marcador2: 'Katya Soler-Romero, M.Sc., PhD em Ciências Biológicas, Departamento de Botânica, Instituto de Biologia, pós-doutoranda na Universidade Nacional Autônoma do México;',
    marcador3: 'Rina Fabiola Díaz, Bióloga, M.Sc., em Botânica, Assistente de pesquisa do Herbário Paul C. Standley (EAP), Universidade de Zamorano;',
    marcador4: 'Mayra Núñez Vallecillos, Bióloga, M.Sc., Doutoranda, Assistente da Coral Reef Alliance, Colaboradora da base de dados;',
    marcador4b: 'Olvin Oyuela, Biólogo, M.Sc., Assistente técnico da Universidade Nacional Autônoma de Honduras, Colaborador da Base de Dados;',
    marcador4c: 'Jorge Pérez, Biólogo, M.Sc., Assistente técnico da Universidade Nacional Autônoma de Honduras, Colaborador da Base de Dados.',
    marcador4d: '',
    marcador4e: '',
    paragrafo4: 'Atribuições do Comitê Científico:',
    marcador5: 'Decisão sobre a inclusão de espécies novas na base de dados;',
    marcador6: 'Decisão sobre a eliminação de espécies por falta ou inconsistência de dados;',
    marcador7: 'Apoio à validação de informações da base de dados;',
    marcador8: 'Apoio na revisão e busca de dados;',
    marcador9: 'Apoio à busca e consulta a colaboradores e especialistas para aporte e validação de dados;',
    marcador10: 'Recomendações de melhorias na base de dados;',
    marcador11: 'Melhoria contínua do nível de informação.'
  },
  especiesInstitucional: {
    titulo: 'Espécies',
    paragrafo1: 'Pesquise aqui informações detalhadas sobre espécies exóticas invasoras e potencialmente invasoras presentes na República de Honduras, os especialistas que as estudam, os projetos dedicados a entender seu comportamento, seus efeitos e opções de controle, bem como as publicações que são publicadas.',
    paragrafo2_1: 'Se você tiver informações adicionais, convidamos você a colaborar para melhorar o conhecimento sobre esse problema, ',
    paragrafo2_2: 'ajudando a manter esses bancos de dados completos e atualizados.',
    verTutorial: 'Abrir tutorial',
    paragrafo3: 'Por favor use o seguinte formato para citar informação consultada neste sistema:',
    paragrafo4_1: 'Base de Dados Nacional de Espécies Exóticas Invasoras de Honduras. Institute of Honduras. Consultado em DD/MM/AAA.',
    paragrafo4_2: ' Acessado em __/__/____ (data)',
    tutorialTitulo1: 'Como citar a base de dados',
    tutorialDescricao1: 'Veja aqui o formato da referência para citação da base de dados em publicações.',
    tutorialTitulo2: 'Use os filtros',
    tutorialDescricao2: 'Aqui temos algumas opções para filtrar conteúdo.',
    tutorialTitulo3: 'Use os filtros: selecione dados',
    tutorialDescricao3: 'Escolha filtros e faça combinações para selecionar dados por local, forma biológica, uso, grupo taxonômico, etc.',
    tutorialTitulo4: 'Número de registros',
    tutorialDescricao4: 'Este é o número de espécies exóticas invasoras na base de dados ou resultante das filtragens realizadas.',
    tutorialDescricao5: 'Aqui você pode selecionar quantos registros visualizar por página.',
    tutorialTitulo6: 'Lista de espécies exóticas invasoras',
    tutorialDescricao6: 'Listagem de todas as espécies ou resultados do uso de filtros. Clique no nome da espécie para ver os dados completos.',
    tutorialTitulo6_1: 'Exportar dados',
    tutorialDescricao6_1: 'Aqui você pode filtrar dados e escolher o formato para exportar.',
    tutorialTitulo7: 'Taxonomia',
    tutorialDescricao7: 'Classificação taxonômica da espécie selecionada, nomes populares, sinônimos e uma foto quando possível!',
    tutorialTitulo8: 'Ecologia e uso',
    tutorialDescricao8: 'Descrição, biologia e ecologia da espécie selecionada, de onde vem, que ambientes invade e usos econômicos.',
    tutorialTitulo9: 'Introdução e dispersão',
    tutorialDescricao9: 'Como a espécie chegou onde é invasora, por quê foi introduzida e como se dispersa para outros lugares.',
    tutorialTitulo10: 'Impactos',
    tutorialDescricao10: 'Ambientais, econômicos, à saúde e culturais. Classificação de impactos ambientais pelo protocolo EICAT – IUCN.',
    tutorialTitulo11: 'Manejo',
    tutorialDescricao11: 'Medidas preventivas, de detecção precoce, controle mecânico, químico e biológico para a espécie selecionada.',
    tutorialTitulo12: 'Análise de Risco',
    tutorialDescricao12: 'Resultados de análises de risco para invasão biológica realizadas para a espécie selecionada.',
    tutorialTitulo13: 'Ocorrências',
    tutorialDescricao13: 'Locais, ambientes, unidades de conservação, estados onde a espécie ocorre no Brasil e estágio de invasão – veja o mapa!',
    tutorialDescricao14: 'Lista de referências usadas para compilar dados sobre a espécie selecionada e outras publicações que citam a espécie.',
    tutorialTitulo15: 'Projetos',
    tutorialDescricao15: 'Lista de projetos de pesquisa e manejo que contemplam a espécie selecionada.'
  },
  colaboradoresInstitucional: {
    titulo: 'Busca por colaboradorxs',
    paragrafo1: 'Contribuidores incluem aqueles que contribuem com dados para este sistema de informação, bem como aqueles que coletaram espécimes de museus ou herbários ou são autores de publicações que foram usadas como fontes de dados. ',
    paragrafo2: ' ',
    paragrafo3: 'Por favor use o seguinte formato para citar informação consultada neste sistema:',
    paragrafo4_1: 'Base de Dados Nacional de Espécies Exóticas Invasoras de Honduras. Institute of Honduras. Consultado em DD/MM/AAA.',
    paragrafo4_2: ' Acessado em __/__/____ (data)'
  },
  acuaculturaInstitucional: {
    titulo: 'Aquicultura',
    paragrafo1: 'A aquicultura é a principal via de introdução de espécies de peixes exóticos em Honduras. Desde o ano de 1954 foram introduzidas ao menos dez espécies de peixes de diferentes famílias, nove para fins de produção e uma com objetivo de pesca.',
    paragrafo2: 'A piscicultura do país está dominada pela criação de duas espécies de tilápia, a de Moçambique Oreochromis mossambicus e a do Nilo Oreochromis niloticus.',
    paragrafo3: 'A primeira é registrada em dois departamentos e numa vertente que desemboca no Oceano Pacífico, enquanto que a segunda está em 15 departamentos e 17 vertentes, 12 do Oceano Atlântico e 5 do Pacífico.',
    paragrafo3b: '',
    paragrafo4: 'Tilapia - Oreochromis niloticus',
    paragrafo4b: 'Foto: Jorge Alberto Perez Antunez'
  },
  acuariofiliaInstitucional: {
    titulo: 'Aquariofilia',
    paragrafo1: 'A origem da aquariofilia não está documentada em Honduras, tampouco a introdução das primeiras espécies de peixes ornamentais. É comum, atualmente, encontrar peixes exóticos em lojas de animais de companhia e em páginas na internet, havendo entusiastas que se dedicam à compra e venda dessas espécies em várias cidades no país.',
    paragrafo2: 'A Lei Geral de Pesca e Aquicultura de Honduras não conta com regulamentação para a introdução e a distribuição de peixes ornamentais, de modo que essa atividade é realizada geralmente de forma ilegal, com exceção a alguns aquários privados que fazem os trâmites necessários através do Serviço Nacional de Sanidade Agropecuária e de Prefeituras locais.',
    paragrafo3: 'Cabe destacar que o Acordo Ministerial 0740-2019 da Secretaria de Energia, Recursos Naturais, Ambiente e Minas conta com uma tabela de categorização de projetos aquícolas na qual os aquários estão na categoria 3 de impacto ambiental.',
    paragrafo4: '',
    marcador1: '',
    marcador2: '',
    marcador3: '',
    paragrafo5: 'Acara azul - Andinoacara pulcher',
    paragrafo5b: 'Foto: Gabriel González',
    paragrafo6: 'Betta, luchador de siam - Betta splendens',
    paragrafo6b: 'Foto: Gabriel González',
    paragrafo7: 'Pez dorado - Carassius auratus',
    paragrafo7b: 'Foto: Gabriel González',
    paragrafo8: 'Pez cebra - Danio rerio',
    paragrafo8b: 'Foto: Gabriel González',
    paragrafo9: 'Escalar, pez ángel - Pterophyllum scalare',
    paragrafo9b: 'Foto: Gabriel González',
    paragrafo10: 'Tetra cardenal - Paracheirodon axelrodi',
    paragrafo10b: 'Foto: Gabriel González',
    paragrafo11: 'Tilapia - Oreochromis sp',
    paragrafo11b: 'Foto: Gabriel González'
  },
  especiesMarinoCosterasInstitucional: {
    titulo: 'Espécies marinho-costeiras',
    paragrafo1: 'Espécies exóticas invasoras marinho-costeiras são de difícil detecção e geralmente introduzidas por atividades humanas como a descarga de água de lastro ou bioincrustação, quando chegam aderidas a cascos de navios. Outra via de introdução é por correntes marinhas, como no caso do peixe-leão Pterois volitans, introduzido como peixe de aquário na Flórida, Estados Unidos, de onde escapou e se dispersou para todo o Mar do Caribe, chegando no Oceano Atlântico. Outras espécies são introduzidas em função de falta de segurança em cultivos de espécies exóticas como camarões e peixes, que escapam para o meio ambiente.',
    paragrafo2: 'Essas espécies invasoras em novos hábitats têm êxito no estabelecimento tanto em função de condições ambientais favoráveis, como pela ausência de predadores naturais que controlem a população. Espécies invasoras podem provocar desequilíbrio ecológico ao predar espécies nativas e ao competir por espaço e alimento com espécies locais, excluindo ou reduzindo populações nativas, com impacto à biodiversidade, a ecossistemas e ao bem estar humano.',
    paragrafo3: '',
    paragrafo3b: '',
    paragrafo4: 'Pez león - Pterois volitans',
    paragrafo4b: 'Foto: Mayra Vallecillos',
    paragrafo5: 'Musa velutina - banano rosa',
    paragrafo5b: 'Foto: Eduardo Chacón-Madrigal',
    paragrafo6: 'Nylanderia fulva - hormiga loca',
    paragrafo6b: 'Foto: Bentleypkt',
    paragrafo7: 'Syzygium jambos - manzana rosa',
    paragrafo7b: 'Foto: Eduardo Chacón-Madrigal',
    paragrafo8: 'Ulex europaeus - chucero',
    paragrafo8b: 'Foto: Eduardo Chacon-Madrigal'
  },
  ornamentalesInstitucional: {
    titulo: 'Plantas ornamentais',
    paragrafo1: 'Há registro de cerca de 700 espécies exóticas em Honduras, que representam 305 espécies casuais e 427 estabelecidas (Rojas-Sandoval et al., 2022), sendo que as últimas representam 6,4% da flora de Honduras. Durante décadas, o paisagismo e a jardinagem utilizaram espécies exóticas com a introdução sucessiva de espécies. Estas espécies são usadas no ajardinamento de casas e quintais, ruas em áreas urbanas, parques urbanos, praças e centros de visitantes de algumas áreas protegidas, entre outros, com o objetivo de embelezar esses espaços, proporcionar sombra, alimento para animais silvestres, recursos florais para insetos, aves e morcegos e refúgios de flora e fauna.',
    paragrafo2: 'A introdução dessas espécies permite que escapem desses espaços e colonizem áreas naturais, levando à exclusão de espécies nativas. São exemplos a orquídea africana Oeceoclades maculata e a espada-de-são-jorge Sansevieria trifasciata, registradas em algumas áreas protegidas, assim como a tulipa-africana Spathodea campanulata, casuarina Casuarina equisetifolia, bambu Bambusa vulgaris, maria-sem-vergonha Impatiens balsamina e amarelinha Thunbergia alata, amplamente distribuídas e invasoras nos Neotrópicos.',
    paragrafo3: '',
    paragrafo4: 'Corona de cristo - Euphorbia milii',
    paragrafo4b: 'Foto: Herbario TEFH',
    paragrafo5: 'Bastón del emperador - Etlingera elatior',
    paragrafo5b: 'Foto: Herbario TEFH',
    paragrafo6: 'Orquidea africana - Oeceoclades maculata',
    paragrafo6b: 'Foto: Herbario TEFH',
    paragrafo7: 'Llama del bosque - Spathodea campanulata',
    paragrafo7b: 'Foto: Herbario TEFH',
    paragrafo8: 'Llama del bosque - Spathodea campanulata',
    paragrafo8b: 'Foto: Herbario TEFH',
    paragrafo9: 'Almendra - Terminalia catappa',
    paragrafo9b: 'Foto: Herbario TEFH'
  },
  plantasForrajerasInstitucional: {
    titulo: 'Plantas forrageiras',
    paragrafo1: 'Espécies forrageiras têm sido usadas em Honduras para alimento de gado bovino, aves de criação, cabras e porcos, entre outros, favorecendo a produção e reprodução de animais domésticos. A maioria das espécies pertencem às famílias Poaceae, das gramíneas, e Fabaceae, que inclui o feijão e a soja.',
    paragrafo2: 'Em Honduras, várias instituições públicas e privadas e organizações não governamentais têm proposto a introdução de espécies forrageiras para fins de alimentação animal. Essas espécies são utilizadas em função do rápido crescimento, pois em geral são herbáceas adaptadas a climas secos, toleram solos inundados, competem bem com ervas daninhas, são palatáveis ao gado e têm alto valor nutritivo, entre outros.',
    paragrafo2a: 'Essas espécies introduzidas se estabeleceram em áreas abertas ou desmatadas em fases iniciais de desenvolvimento de florestas.',
    paragrafo2b: 'Algumas espécies forrageiras também são usadas para formar cercas vivas, para a proteção de taludes, como quebra vento, controle de erosão, cobertura e recuperação de solos, lavração, adubo verde, tinturas, plantas melíferas, recurso floral para polinizadores, sistemas agroflorestais, uso ornamental, como no caso do amendoim-forrageiro Arachis pintoi, uso medicinal como no caso da ervilha-azul Clitoria ternatea e mucuna-anã Mucuna pruriens, e uso como inseticida, como no caso do feijão-de-porco Canavalia ensiformis.',
    paragrafo3: 'Pasto guinea - Megathyrsus maximus',
    paragrafo3b: 'Foto: Arles Garcia',
    paragrafo4: 'Pasto guinea - Megathyrsus maximus',
    paragrafo4b: 'Foto: Arles Garcia'
  },
  mascotesInstitucional: {
    titulo: 'Animais de estimação e peixes ornamentais',
    paragrafo1: 'O comércio de animais de estimação, ou pets, é uma das principais vias de introdução de espécies exóticas invasoras em função da diversidade de espécies e da quantidade de indivíduos. As pessoas compram répteis, aves e peixes através de websites na internet ou de lojas especializadas. Ainda que a maior parte dos animais seja mantida em cativeiro, alguns acabam sendo soltos em ambientes naturais porque as pessoas não podem mais mantê-los ou porque escapam.',
    paragrafo2: 'Os animais liberados têm chance de se estabelecerem se encontram condições adequadas e podem transmitir doenças ou competir com espécies nativas, gerando impactos negativos a ecossistemas naturais. Espécies animais são exportadas como animais de companhia e também para fins alimentares ou para decoração de aquários, como no caso de plantas aquáticas, ou terrários, que conformam o ambiente onde devem viver os animais de estimação.',
    paragrafo3: '',
    paragrafo4: 'Carassius auratus - carpa',
    paragrafo4b: 'Foto: Felipe Aira',
    paragrafo5: 'Lonchura malacca - munia tricolor',
    paragrafo5b: 'Foto: Shino Jacob Koottanad',
    paragrafo6: 'Poecilia reticulata - Guppy',
    paragrafo6b: 'Foto: Vincent Eisfeld ',
    paragrafo7: 'Xiphophorus variatus - Plati variado',
    paragrafo7b: 'Foto: Marrabbio'
  },
  plantasOrnamentaisInstitucional: {
    titulo: 'Plantas ornamentais',
    paragrafo1: 'Plantas ornamentais são usadas para fins de jardinagem e paisagismo em áreas urbanizadas ou para desenho de interiores e produção de flores. O uso de plantas ornamentais representa uma atividade econômica crescente e milionária em todo o mundo. Devido à globalização e à alta demanda, o comércio de plantas ornamentais gera intensa movimentação de plantas entre países e regiões. Assim, esta atividade acaba por causar danos ecológicos por ser realizada sem avaliação dos riscos da introdução.',
    paragrafo2: 'Atualmente, a introdução de plantas ornamentais é a principal fonte de plantas exóticas invasoras em muitos países. Mais de 50% de todas as espécies de plantas exóticas invasoras e 85% das árvores exóticas invasoras foram originalmente introduzidas como ornamentais na Costa Rica. Os danos ecológicos podem ser irreversíveis e gerar perdas econômicas. Regulamentar o comércio de plantas ornamentais é uma meta para as políticas de sustentabilidade em diversos países.',
    paragrafo3: '',
    paragrafo4: 'Impatiens hawkeri - china',
    paragrafo4b: 'Foto: Eduardo Chacon Madrigal',
    paragrafo5: 'Spathodea campanulate - Llama del bosque',
    paragrafo5b: 'Foto: Eduardo Chacon Madrigal',
    paragrafo6: 'Thunbergia alata - ojo de poeta',
    paragrafo6b: 'Foto: Eduardo Chacon Madrigal'
  },
  projetosInstitucional: {
    titulo: 'Projetos',
    paragrafo1: 'Esta seção inclui informações sobre projetos atuais ou anteriores focados no estudo e manejo de espécies exóticas invasoras e potencialmente invasoras presentes em Honduras.',
    paragrafo2_1: 'Si participa o participó en algún proyecto relacionado con estas especies, por favor ayude a incorporarlo enviando la información correspondiente a',
    paragrafo2_2: 'enviando informação sobre o projeto ',
    paragrafo2_3: 'para que possamos cadastrá-lo. Esperamos que a divulgação beneficie o seu trabalho.',
    paragrafo3: 'Por favor use o seguinte formato para citar informação consultada neste sistema:',
    paragrafo4_1: 'Base de Dados Nacional de Espécies Exóticas Invasoras de Honduras. Institute of Honduras. Consultado em DD/MM/AAA.',
    paragrafo4_2: 'Acessado em __/__/____ (data)'
  },
  referenciasInstitucional: {
    titulo: 'Referências',
    paragrafo1: 'As publicações e relatórios técnicos aqui citados foram utilizados para agregar informações sobre as espécies presentes no território nacional, seu comportamento e efeitos. Alguns incluem dados sobre opções de manejo para diferentes espécies. ',
    paragrafo2: 'Se você é autor ou conhece publicações sobre o assunto que ainda não constam nesta seção, ajude a incorporá-las enviando as citações correspondentes para',
    paragrafo3: 'Contribua com dados no site',
    paragrafo4: 'Por favor use o seguinte formato para citar informação consultada neste sistema:',
    paragrafo5_1: 'Base de Dados Nacional de Espécies Exóticas Invasoras de Honduras. Institute of Honduras. Consultado em DD/MM/AAA.',
    paragrafo5_2: 'Acessado em __/__/____ (data)'
  },
  usoFlorestalInstitucional: {
    titulo: 'Uso florestal',
    paragrafo1: 'Cerca de 56% do território de Honduras tem cobertura florestal, sendo 18% dominado por florestas de coníferas.',
    paragrafo2: 'Diversas espécies foram introduzidas para produção de madeira em função do rápido crescimento e para uso em sistemas agroflorestais, como a limba Terminalia superba e o mogno africano Khaya senegalensis. Na região de Lempira, em áreas de alta declividade, é utilizado o sistema Quesungual, que combina espécies florestais nativas com espécies frutíferas introduzidas.',
    paragrafo3: 'Alguns projetos de plantação florestal liderados por instituições públicas e privadas e organizações não governamentais propuseram o uso de espécies exóticas. A introdução de espécies invasoras gera, como consequência, a exclusão de espécies nativas e endêmicas dos ecossistemas naturais do país, podendo também gerar impactos sobre recursos hídricos em função do alto consumo de água por essas espécies de rápido crescimento.',
    paragrafo4: 'Limba - Terminalia superba',
    paragrafo4b: 'Foto: Alchetron Free Social Encyclopedia'
  },
  contatoInstitucional: {
    cabecalhoTitulo: 'Contato',
    contato: 'Contato',
    p1: 'Preencha todos os campos do formulário abaixo:',
    nome: 'Nome',
    email: 'E-mail',
    instituicao: 'Instituição',
    mensagem: 'Mensagem',
    enviar: 'Enviar'
  },
  colaboreDados: {
    cabecalhoTitulo: 'Colabore com informação',
    paragrafo1: 'Nossa base de dados é atualizada graças a contribuições de especialistas e observadores da natureza distribuídos em todo o território nacional. Antes de serem publicadas, as informações fornecidas são validadas segundo critérios acordados em nível regional.',
    paragrafo2: 'Você pode enviar dados sobre espécies ainda não incluídas neste sistema ou sobre novas localidades de ocorrência baixando nosso aplicativo (Invasoras AR) ou enviando um e-mail para',
    paragrafo2b: 'Por e-mail você também pode enviar publicações ou informação sobre projetos que abordam invasões biológicas em Honduras. Os provedores de dados que contribuem regularmente podem receber um nome de usuário e senha para inserir informações diretamente na base de dados.',
    paragrafo3: 'Ajude-nos a enfrentar o desafio de espécies exóticas invasoras com base em informações completas, atualizadas e confiáveis!',
    paragrafo4: 'Obrigadx!'
  }
}
