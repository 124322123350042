export const es = {
  message: {
    requiredField: 'Campo obligatorio',
    noRecords: '¡No se encontraron registros!',
    confirmDel: '¿Está seguro de que quiere borrar?',
    confirmApproval: '¿Está seguro de que desea aprobar esta ocurrencia?',
    noResultsFor: 'No hay resultados para'
  },
  login: {
    username: 'Usuario',
    password: 'Contraseña',
    login: 'Login',
    entrar: 'Iniciar sesión',
    descricao: 'Ingrese sus datos de acceso en el siguiente formulario:'
  },
  exportarFicha: {
    author: 'autor',
    biodiversity_impact: 'impacto sobre la biodiversidad',
    chemical_control: 'control_químico',
    class: 'Clase',
    common_name: 'nombre_común',
    control_biological: 'control_biológico',
    data_bibliography: 'datos_bibliografía',
    economic_impact: 'impacto_económico',
    economic_use: 'uso_económico',
    family: 'familia',
    health_impact: 'impacto_salud',
    intro_data: 'datos_introducción',
    intro_description: 'descripción_introducción',
    invasion_preferential_environments: 'ambientes_preferenciales_invasión',
    kingdom: 'Reino',
    location: 'localidad',
    morphophysiology_description: 'descripción_morfofisiología',
    native_distribution_area: 'áreas_nativa_de_distribución',
    natural_environment: 'ambiente_natural',
    order: 'Órden',
    origin: 'origen',
    phyllum: 'Phyllum',
    physical_control: 'control_físico',
    prevention: 'prevención',
    scientific_name: 'nombre_científico',
    social_impact: 'impacto_social',
    species_id: 'id_especie',
    voc_cause_introduction: 'voc_causa_de_introducción',
    voc_diet: 'voc_dieta',
    voc_dispersal_routes: 'voc_rutas_dispersión',
    voc_dispersion_vectors: 'voc_vectores_dispersión',
    voc_form: 'voc_forma',
    voc_introduction_type: 'voc_tipo_introducción',
    voc_reproduction: 'voc_reproducción',
    voc_spread: 'voc_dispersión',
    world_invasive_place: 'sitios_invasión_mundo'
  },
  label: {
    voltar: 'Vuelve',
    outro: 'Otro',
    filtros: 'Filtros',
    acesseBaseDados: 'Acceda a la base de datos',
    conhecaNossaRede: 'Red Latino Americana',
    baseDadosNacional: 'Bases de datos nacionales',
    politicaDeDados: 'Política de datos',
    comiteCientifico: 'Comité científico',
    colabore: 'Aportar datos',
    especiesMarinhas: 'Especies marinas',
    peixesAquario: 'Peces de acuario',
    peixesAquicultura: 'Peces de acuicultura',
    pescaDesportiva: 'Pesca deportiva',
    pets: 'Animales de compañía',
    plantasForrageiras: 'Plantas forrajeras',
    plantasOrnamentais: 'Plantas ornamentales',
    usoFlorestal: 'Uso forestal',
    tutorial: 'Tutorial',
    especies: 'Especies',
    colaboradores: 'Colaboradorxs',
    referencias: 'Referencias',
    projetos: 'Proyectos',
    saibaMais: 'Más info',
    contida: 'Contenida',
    casual: 'Casual',
    estabelecida: 'Establecida',
    invasora: 'Invasora',
    exibirListagem: 'Mostrar listado',
    concluir: 'Terminar',
    fechar: 'Cerrar',
    próximo: 'Próximo',
    anterior: 'Anterior',
    clickAqui: 'Clic aquí',
    numRegistrosEncontrados: 'Número de registros encontrados:',
    exibir10Reg: 'Mostrar 10 registros',
    exibir25Reg: 'Mostrar 25 registros',
    exibir50Reg: 'Mostrar 50 registros',
    exibir100Reg: 'Mostrar 100 registros',
    limpar: 'Limpiar',
    buscar: 'Buscar',
    addColaborador: 'Agregar colaborador/a',
    nome: 'Nombre',
    sobrenome: 'Apellido',
    aprovarColaborador: 'Aprobar colaborador/a',
    salvarColaborador: 'Salvar colaborador/a',
    titulo: 'Título',
    cargo: 'Cargo',
    especialidade: 'Especialidad',
    instituicao: 'Institución',
    endereco: 'Dirección',
    estado: 'Estado',
    cidade: 'Ciudad',
    pais: 'País',
    email: 'E-mail',
    telefone: 'Teléfono',
    telefone2: 'Teléfono 2',
    websiteURL: 'Website URL',
    midiaSocial1: 'Red social 1',
    midiaSocial2: 'Red social 2',
    midiaSocial3: 'Red social 3',
    observacoesAdicionais: 'Observaciones adicionales',
    adicionar: 'Agregar',
    editando: 'Editando',
    acoes: 'Acciones',
    usuarixs: 'Usuarios',
    adicionarUsuario: 'Agregar usuarix',
    descricao: 'Descripción',
    ativo: 'Activo',
    sim: 'Sí',
    nao: 'No',
    salvarUsuarix: 'Salvar usuarix',
    senha: 'Contraseña',
    observacoes: 'Observaciones',
    grupos: 'Grupos',
    basico: 'Básico',
    administrator: 'Administrador',
    taxonomia: 'Taxonomía',
    nomeCientifico: 'Nombre científico',
    adicionarEspecie: 'Agregar especie',
    genero: 'Género',
    familia: 'Familia',
    ordem: 'Orden',
    classe: 'Clase',
    phylumDivisao: 'Phylum / División',
    reino: 'Reino',
    phylum: 'Phylum',
    origem: 'Origen',
    descricaoInvalida: 'Descripción no válida',
    selecioneFamilia: 'Por favor, seleccione una familia.',
    selecioneOrdem: 'Por favor, seleccione un Orden.',
    selecioneClasse: 'Por favor, seleccione una Clase.',
    selecioneDivisao: 'Por favor, seleccione una División.',
    selectioneReino: 'Por favor, seleccione un Reino.',
    selectioneGenero: 'Por favor, seleccione un género.',
    addReferencia: 'Agregar referencia',
    autoresEX: 'Autorxs (ex. ZILLER, S. R.; DECHOUM, M. S.)',
    aprovarReferencia: 'Aprobar referencia',
    salvarReferencia: 'Salvar referencia',
    autorxsEX: 'Autorxs (ex. ZILLER, S. R.; DECHOUM, M. S.)',
    autores: 'Autorxs',
    local: 'Localidad (Ex. Salvador, BA  /  Sudáfrica: Ciudad del Cabo)',
    localFiltro: 'Localidad',
    edicao: 'Edición (ex. 2 ed.)',
    editora: 'Editorial',
    revista: 'Revista',
    anoPublicacao: 'Año de publicación',
    meioDivulgacao: 'Medio de comunicación',
    volume: 'Volumen (ex. v. 3)',
    numero: 'Número (ex. n. 28)',
    pagina: 'Páginas (ex. p. 25-34  /  245p.)',
    url: 'DOI / URL',
    palavraChave: 'Palabra clave',
    anoPublic: 'Año publicación',
    addProjeto: 'Agregar proyecto',
    aprovarProjeto: 'Aprobar Proyecto',
    salvarProjeto: 'Salvar proyecto',
    contato: 'Contacto',
    areaGeografica: 'Área geográfica',
    objetivos: 'Objetivos',
    breveDescricao: 'Breve descripción',
    dataInicio: 'Fecha de inicio',
    dataTermino: 'Fecha de finalización',
    comentarios: 'Comentarios',
    anoInicio: 'Año de inicio',
    anoTermino: 'Año de finalización',
    ocorrencias: 'Ocurrencias',
    exportarDados: 'Exportar datos',
    addOcorrencia: 'Agregar ocurrencia',
    visualizarOcorrencia: 'Ver ocurrencia',
    alterarOcorrencia: 'Modificar ocurrencia',
    ocorrenciasVinculadas: 'Ocurrencias vinculadas',
    areaProtegida: 'Área protegida',
    especie: 'Especie',
    aprovarOcorrencia: 'Aprobar ocurrencia',
    salvarOcorrencia: 'Salvar ocurrencia',
    colaborador: 'Colaborador(a)',
    municipio: 'Municipalidad',
    municipioSede: 'Municipalidad (sede)',
    municipioCentroide: 'Municipalidad (centroide)',
    referenciaLocal: 'Referencia local',
    referencia: 'Referencia',
    latitude: 'Latitud',
    longitude: 'Longitud',
    coordenadasCorrespondem: 'Las coordenadas corresponden a',
    distanciaAproximada: 'Distancia aproximada',
    ambiente: 'Ambiente',
    baciaHidro: 'Cuenca hidrográfica',
    descricaoInvasao: 'Descripción de la invasión',
    abundancia: 'Abundancia',
    situacaoPopulacional: 'Situación poblacional',
    manejo: 'Manejo',
    dataEntrada: 'Fecha de ingreso',
    dataObservacao: 'Fecha de observación (ej. 2020 o 13/05/2020)',
    validado: 'Validado',
    especieMuseuHerbario: 'Es un ejemplar de museo o de herbario',
    selecioneTipo: 'Seleccione el tipo',
    herbario: 'Herbario',
    museu: 'Museo',
    numeroExemplaInstituicao: 'Número de ejemplar (institución)',
    numeroExemplarColetor: 'Número de ejemplar (colector)',
    selecioneOpcao: 'Seleccione una opción…',
    pointList1: 'A un punto de ocurrencia de uno o más individuos de la especie',
    pointList2: 'Al punto central de un grupo de organismos de la especie',
    pointList3: 'A un punto próximo de la ocurrencia, ubicado a una distancia aproximada de :',
    coordinatesSourceList1: 'A - Una unidad política o administrativa',
    coordinatesSourceList2: 'B - El sitio real de ocurrencia',
    ultimoRegistroBaseDados: 'Últimos registros en la base de datos',
    ultimosAcessos: 'Últimos accesos',
    fichaTecnicaEspecie: 'Ficha técnica de las especies',
    fichaTecnicaEspecieMMA: 'Ficha técnica de las especies MMA',
    fichaTecnicaEspecieMMA2: 'Ficha técnica de las especies MMA versión 2',
    invasoras: 'Invasora',
    contidasBrasil: 'Contenida',
    ausentesBrasil: 'Ausente',
    deficiencia: 'Datos insuficientes (DD)',
    formatosDisponiveisExportacao: 'Formatos disponibles para exportación:',
    biologiaEcologia: 'Biología y ecología',
    usoEconomico: 'Uso económico',
    usoEconomicoDescricao: 'Uso económico - descripción',
    invasaoBiologica: 'Invasión biológica',
    impactos: 'Impactos',
    habitat: 'Hábitat',
    nomeCientificoSinonimos: 'Nombre científico + sinónimos',
    nomesPopulares: 'Nombres comunes',
    reproducao: 'Reproducción',
    dispersao: 'Dispersión',
    dieta: 'Dieta',
    formaBiologica: 'Forma biológica',
    ambientesPreferenciaisInvasao: 'Ambientes preferenciales de invasión',
    tipoIntroducao: 'Tipo de introducción',
    causaIntroducao: 'Causa de introducción',
    localIntroducao: 'Sitio de introducción',
    anoIntroducao: 'Año de introducción',
    marinho: 'Marino',
    dulcicola: 'Agua dulce',
    terrestre: 'Terrestre',
    validarEspecie: 'Validar especie',
    salvarEspecie: 'Salvar especie',
    data: 'Data',
    confiabilidade: 'Confiabilidad',
    resultadosAnalisesRisco: 'Resultados del análisis de riesgo',
    risco: 'Riesgo',
    areaOrigem: 'Área de orígen',
    areaDistribuicaoNatural: 'Área de distribución natural',
    ambientesNaturais: 'Ambientes naturales',
    descricaoEspecie: 'Descripción de la especie',
    ambientesPreferenciaisInvasaoDescricao: 'Ambientes preferenciales de invasión - descripción',
    outrosLocaisOndeEspecieInvasora: 'Otros sitios donde la especie se comporta como invasora',
    impactosEcologicos: 'Impactos ambientales',
    impactosEconomicos: 'Impactos económicos',
    impactosSociais: 'Impactos sociales',
    impactosSaude: 'Impactos sobre la salud',
    categoriaEICAT: 'Categoria EICAT',
    mecanismosImpactoEICAT: 'Mecanismos de impacto EICAT',
    nivelConfiancaAvaliacaoEICAT: 'Nivel de confianza de la evaluación EICAT',
    referenciaEICAT: 'Referencia EICAT',
    dataEICAT: 'Fecha EICAT (ej. 2021)',
    categoriaSEICAT: 'Categoria SEICAT',
    mecanismosImpactoSEICAT: 'Mecanismos de impacto SEICAT',
    nivelConfiancaAvaliacaoSEICAT: 'Nivel de confianza de la evaluación SEICAT',
    referenciaSEICAT: 'Referencia SEICAT',
    dataSEICAT: 'Fecha EICAT (ej. 2021)',
    digitarSomenteAno: 'Ingresar solamente el año - 2021',
    viasCDBCategorias: 'Vías CDB - categorías',
    viasIntroducao: 'Vías de introducción y dispersión',
    viasVetoresCDBSubcategorias: 'Vías y vectores CDB - subcategorías',
    vetoresIntroducao: 'Vectores de introducción y dispersión',
    introducao: 'Introducción',
    adicionarIntroducao: 'Agregar introducción',
    ano: 'Año',
    descricaoIntroducao: 'Descripción de la introducción',
    salvar: 'Salvar',
    cancelar: 'Cancelar',
    excluir: 'Eliminar',
    medidasPreventivas: 'Medidas de prevención',
    medidasPreventivasMMA: 'Medidas de prevención MMA',
    medidasDeteccaoPrecoce: 'Medidas de detección temprana',
    controleMecanico: 'Control mecánico',
    controleQuimico: 'Control químico',
    controleBiologico: 'Control biológico',
    distribuicaoPais: 'Distribución en el país',
    vejaMapaOcorrencias: 'Vea el mapa de ocurrencias',
    ecologiaUso: 'Ecología y uso',
    introducaoDispersao: 'Introducción y dispersión',
    analiseRisco: 'Análisis de riesgo',
    nomeComum: 'Nombre común',
    foto: 'Foto',
    credito: 'Crédito',
    sinonimos: 'Sinónimos',
    autor: 'Autor',
    subespecie: 'Subespecie',
    autorSubespecie: 'Autor - subespecie',
    pendenteAnalise: 'Pendiente de análisis',
    cancelarVoltar: 'Cancelar / Volver',
    versaoAnterior: 'Versión anterior',
    versaoAtual: 'Versión actual',
    colaboradorxs: 'Colaboradorxs',
    consultaTaxonomiaListaCompletaEspecies: 'Taxonomía - Lista completa de especies',
    consultaEspeciesOrigemAmbientes: 'Especies, origen, ambientes de invasión e impactos',
    consultaEspeciesManejo: 'Especies y manejo',
    consultaParaGerarFichaTecnica: 'Ficha técnica de la especie sin ocurrencias',
    consultaEspeciesOcorrencias: 'Especies y ocurrencias',
    exportarDadosConsultas: 'Exportar consultas',
    ocorrenciasApp: 'Ocurrencias APP',
    dataCadastro: 'Fecha de registro',
    criadoPor: 'Creado por',
    nivelCerteza: 'Nivel de certidumbre',
    byApp: 'Por aplicación móvil',
    verDados: 'Ver datos',
    appuser: 'APPUser',
    profissao: 'Profesión',
    colobadorJaCadastrado: 'Colaborador ya registrado',
    especialidadeApp: 'Especialidad APP',
    cep: 'Código postal',
    categoriaCDB: 'Categoría CBD',
    subCategoriaCDB: 'Subcategoría CDB',
    mensagemEnviada: 'Mensaje enviado correctamente.'
  },
  home: {
    destaque1: 'Bienvenid@s a la Base de Datos Nacional de Especies Exóticas Invasoras!',
    destaqueDescricao1: 'Este sistema de bases de datos recibe aportes de personas comprometidas con la conservación de la biodiversidad. Lo invitamos a recorrer nuestra página!',
    destaque2: 'Desde 2005 documentando especies exóticas invasoras y compartiendo la información online!',
    destaqueDescricao2: 'Este sistema de bases de datos fue desarrollado y se mantiene actualizado con recursos nacionales e internacionales y cuenta con un Comité Científico de apoyo.',
    destaque3: 'Qué incluye este sistema de información?',
    destaqueDescricao3: 'Administramos cuatro bases de datos interconectadas sobre especies, referencias, colaboradores y proyectos.',
    destaque4: '¿Cómo funciona?',
    destaqueDescricao4: 'Puede consultar sobre especies exóticas invasoras y potencialmente invasoras, especialistas, proyectos y bibliografía en la República de Honduras.',
    titulo: 'Busque especies, filtre y exporte datos',
    conteudo: 'Aquí encontrará información acerca del área nativa de distribución de las especies, características biológicas y ecológicas, hábitats invadidos, lugares donde la especie está presente y donde invade en el mundo y en Honduras, incluyendo un mapa de ocurrencias en el país, historia de introducción y uso, vectores y rutas de introducción y de dispersión, información acerca de su manejo y referencias. Utilice los filtros para buscar la información deseada y expórtela en distintos formatos.',
    conteudoEspecies: 'Listas de especies, características ecológicas, rango nativo de distribución, lugares donde la especie invade a nivel global, vectores y rutas de dispersión, análisis de riesgo, usos, opciones de manejo, localidades de ocurrencia en Costa Rica, referencias.',
    conteudoColaboradores: 'Registro de proveedores de datos. Si quiere entrar en contacto con quien proveyó la información sobre alguna especie o un sitio de ocurrencia en particular busque aquí.',
    conteudoReferencias: 'Lista de las referencias relacionadas con las especies incluidas en la base de datos.',
    conteudoProjetos: 'Proyectos de investigación y de manejo de especies exóticas invasoras en la Costa Rica.',
    titulo1: 'Conozca las especies exóticas invasoras.',
    conteudo1: 'Listas de especies exóticas invasoras organizadas según el motivo o vía de introducción a Honduras, usos o efectos sobre los ecosistemas. Las páginas incluyen una explicación sobre cada grupo de especies y vínculos para fuentes de información adicionales.'
  },
  areaParceiros: {
    titulo: 'Red de Bases de Datos sobre Especies Exóticas Invasoras:',
    argentina: 'Argentina',
    paraguai: 'Paraguay',
    uruguai: 'Uruguay'
  },
  redeBaseDeDados: {
    cabecalhoTitulo: 'Red Latino-Americana y del Caribe',
    paragrafo1: 'El origen de esta red de bases de datos se remonta al año 2004, como parte de la Red Temática sobre Especies Exóticas Invasoras de la Red Interamericana de Información sobre Biodiversidad (I3N-IABIN), establecida como una iniciativa de la Cumbre de las Américas.',
    paragrafo2: 'Cada país integrante de I3N designó un líder nacional cuya función era desarrollar una base de datos nacional sobre estándares comunes y compartir información sobre especies exóticas invasoras:',
    paragrafo2_1: '',
    marcador1: 'Argentina: Dr. Sergio M. Zalba, Profesor de la Universidad Nacional del Sur e investigador del CONICET (desde 2004);',
    marcador2: 'Brasil: Dra. Sílvia R. Ziller, Fundadora y Directora Ejecutiva del Instituto Horus de Desarrollo y Conservación Ambiental (desde 2004);',
    marcador3: 'Chile: Dr. Aníbal Pauchard, Professor, Facultad de Ciencias Forestales, Universidad de Concepción.',
    marcador4: 'Costa Rica: PhD. Eduardo Chacón-Madrigal, Profesor de la Universidad de Costa Rica. Investigador en el Centro de Investigación en Biodiversidad y Biología Tropical. Co-coordinador de Flora Invasive and Naturalized of Central America.',
    marcador5: 'Ecuador: Dr. Diego Inclán, Director Ejecutivo del Instituto Nacional de Biodiversidad (desde 2021) y Francisco José Prieto Albuja;',
    marcador5_1: 'Honduras: Dra Lilian Ferrufino, Universidad Nacional Autónoma de Honduras;',
    marcador5_2: 'Jamaica: Dra. Suzanne Davis, Clearing-House Mechanism, Institute of Jamaica;',
    marcador5_3: 'Paraguay: Hugo Fernando del Castillo, Guyra Paraguay (desde 2006);',
    marcador5_4: 'Uruguay: Dr. Ernesto Brugnoli, Profesor de la Universidad de la República, Montevideo (desde 2005), y el MSc. Marcelo Iturburu, Coordinador del Comité Nacional de Especies Exóticas Invasoras del Ministerio de Ambiente (desde 2018).',
    paragrafo3: 'La iniciativa IABIN se extendió hasta el año 2011 con financiamiento del Fondo para el Ambiente Mundial (GEF). Más allá del final de este proyecto, algunos de los líderes nacionales de la red mantuvieron las bases de datos nacionales en funcionamiento (Argentina, Brasil, Jamaica, Paraguay y Uruguay).',
    paragrafo4: 'En el año 2021, se recibió el apoyo de la iniciativa BioBridge, de la Secretaría de la Convención sobre Diversidad Biológica (CDB), para reforzar y actualizar las bases de datos de Argentina, Brasil, Paraguay y Uruguay. La interfase fue actualizada y se incorporaron nuevas funcionalidades.',
    paragrafo5: 'Las personas que han desarrollado la Base de Dados son:',
    marcador6: 'El Programador João Scucato, de Curitiba – PR, Brasil, que desarrolló la primera versión en Microsoft Access, 2004-2005;',
    marcador7: 'El programador Alejandro Moreno, de Argentina, está involucrado con el mantenimiento de la red desde 2006. Mantuvo copias de los archivos y rescató los contenidos de Paraguay y Uruguay para la nueva edición, además de desarrollar la',
    marcador7_1: 'Plataforma Común',
    marcador7_2: 'de los países de la red;',
    marcador8: 'El diseñador Rafael Moura y el programador Thiago Lôbo, vinculados la',
    marcador8_1: 'Plataforma Vibbra',
    marcador8_2: 'en Florianópolis – SC, Brasil;',
    marcador9: 'El programadores Leonardo Rotondano, para el desarrollo de la aplicación para teléfono móvil de reporte de ocurrencias de especies exóticas.',
    paragrafo6: 'La red espera ampliar su cobertura territorial incorporando otros países de la región de manera de ampliar la disponibilidad de información sobre especies exóticas invasoras para el conocimiento público, la gestión gubernamental y el manejo.',
    paragrafo7: 'Red de Bases de Datos sobre Especies Exóticas Invasoras:',
    paragrafo8: '',
    paragrafo9: ''
  },
  baseDadosNacional: {
    cabecalhoTitulo: 'Base de datos nacional',
    titulo: 'La Base de Datos Nacional de Especies Exóticas Invasoras',
    paragrafo1: 'Esta plataforma de información fue desarrollada en el año 2004 mediante una asociación entre el Instituto Horus y la Universidad Nacional del Sur en Bahía Blanca, Argentina (Dr. Sergio Zalba), como parte de la red de información sobre especies exóticas invasoras, entonces llamada red I3N (IABIN Invasives Information Network). La red estaba coordinada, en aquel momento, por Andrea Grosse, una bióloga muy dedicada a la red que hizo posible el desarrollo de la estructura de la base de datos, así como, en el análisis de riesgos, manuales y cursos de formación sobre el tema. Otra persona que tuvo un papel decisivo en el crecimiento de la red fue la Dra. Annie Simpson, del USGS, entonces responsable de la Red Mundial de Información sobre Especies Invasoras (GISIN), cuyo objetivo era establecer normas de información sobre las especies exóticas invasoras para facilitar la comunicación entre las bases de datos mundiales y de los distintos países. Los recursos para el desarrollo de la base de datos fueron proporcionados por el Banco Mundial (GEF) bajo el proyecto coordinado por el Servicio Geológico de los Estados Unidos (USGS) que tenía como objetivo establecer la IABIN - Red Interamericana de Información sobre Biodiversidad.',
    paragrafo1_1: '',
    paragrafo2: 'La Red I3N existió entre los años 2001 y 2011, cuando finalizó el proyecto. Durante este periodo, la estructura de la base de datos se difundió a 21 países de América por medio de cursos de formación para la gestión del tema a nivel nacional y de la propia base de datos: Argentina, Brasil, Uruguay, Chile, Paraguay, Bolivia, Perú, Ecuador, Colombia, Venezuela, Surinam, República Dominicana, Bahamas, Jamaica, Santa Lucía, Panamá, Costa Rica, Honduras, El Salvador, Nicaragua y Guatemala.',
    paragrafo3: 'La mayoría de las bases de datos nacionales se han perdido con el tiempo. Las bases de datos que se han mantenido desde el principio son: Argentina (Universidad Nacional del Sur, Administrador Dr. Sergio Zalba); Brasil (Instituto Horus, Dra. Silvia Ziller y Dra. Michele de Sá Dechoum), Uruguay (Universidad de la República en Montevideo, Dr. Ernesto Brugnoli), asimismo, Jamaica (Institute of Jamaica, Dra. Suzanne Davis).',
    paragrafo4: 'Después de diez años, en 2021, la Iniciativa BioBridge de la Secretaría del Convenio Internacional sobre la Diversidad Biológica en Montreal, Canadá, aportó recursos para una nueva actualización para Argentina, Brasil, Uruguay y Paraguay, y en el 2022 para el inicio de las bases de datos en Costa Rica, Honduras y Chile y para la actualización de la base de datos de Jamaica. Y a la vez, se han incorporado varias mejoras para facilitar a los usuarios la introducción, edición y exportación de datos. Esperamos que esta versión sea más accesible y fácil de usar, y que la base de datos nacional sirva de base para la gestión pública, el manejo en campo y que permita la elaboración de muchos artículos técnicos y científicos. También, esperamos que el conocimiento sobre especies exóticas invasoras estimule el uso de especies nativas, especialmente para proteger la diversidad biológica y la resiliencia de los ecosistemas naturales en el Caribe, Sur y Centro América.',
    paragrafo5: 'Agradecemos a las personas que han colaborado con la Base de Datos Nacional, con la aportación de los datos, así como, en la validación de las especies y distribución, y por todo el apoyo que se ha necesitado. La riqueza de los datos disponibles es, sin duda, el resultado de un importante esfuerzo conjunto.',
    paragrafo6: '',
    paragrafo7: ''
  },
  politicaDeDados: {
    cabecalhoTitulo: 'Política de datos',
    titulo: 'Conceptos y referencias',
    criterio: 'Criterios para la inclusión de especies',
    avaliacao: 'Evaluación y revisión de datos',
    validacao: 'Validación de datos',
    paragrafo1: 'Los conceptos usados este sistema de datos se basan en las definiciones del Convenio de Diversidad Biológica',
    marcador1: 'especie nativa: aquella que se encuentra dentro del área de distribución geográfica donde evolucionó y forma parte de una comunidad biológica en equilibrio;',
    marcador2: 'especie exótica: aquella que se encuentra fuera de su área de distribución natural pasada o presente;',
    marcador3: 'especie exótica invasora: toda especie exótica que afecta o amenaza hábitats, ecosistemas o especies causando alteraciones en los ambientes naturales.',
    paragrafo2: 'La Base de Datos Nacional de Especies Exóticas Invasoras de Honduras tiene enfoque en las especies exóticas invasoras que ya están presentes en el país. Las especies registradas están siempre relacionadas por lo menos con una ocurrencia geográfica en Honduras.',
    paragrafo3: '',
    paragrafo4: 'El sistema de clasificación de ambientes terrestres utilizado es el Manual de Clasificación de la Vegetación Brasilera (IBGE, edición de 2012). Los términos utilizados para ambientes marinos y de agua dulce fueron definidos con especialistas en esas áreas. El vocabulario controlado utilizado en los campos de ambientes preferenciales de invasión, rutas y vectores de dispersión, formas biológicas, impactos y métodos de control fueron definidos de acuerdo con los términos utilizados a por la Base de Datos Global sobre Especies Invasoras del Grupo Especialista en Especies Invasoras de la UICN',
    paragrafo5_1: 'Las referencias taxonómicas principales se toman de los sistemas propuestos por el Jardín Botánico de Missouri ',
    paragrafo5_2: 'The Plant List ',
    paragrafo5_3: 'y por el Sistema Integrado de Información Taxonómica ',
    paragrafo5_4: 'Referencias complementarias incluyen el Registro Mundial de Especies Marinas',
    paragrafo5_5: 'la Base de Datos Global sobre Especies Exóticas Invasoras',
    paragrafo5_6: 'y el Compendio de Especies Invasoras de CABI',
    paragrafo6: 'Para cada punto de ocurrencia las especies se califican como:',
    marcador4: ': cuando su presencia se limita o está restringida a sistemas de cultivo o cría o vive dependiendo de manera directa de las personas, por ejemplo: animal de laboratorio, de acuario, plantas de jardín y sin evidencias de escape de individuos en ese sitio.',
    marcador5: ': cuando la especie fue vista en ambientes naturales o seminaturales, fuera de sistemas de cultivo o cría, todavía sin evidencias de haber establecido un núcleo poblacional.',
    marcador6: ': cuando la especie se reproduce de manera efectiva, conformando una población auto sostenible pero se mantiene en un área limitada, próxima al sitio de introducción local.',
    marcador7: ': cuando la especie avanza, ya sea de manera autónoma o auxiliada por vector antrópicos, hacia otros sitios más allá del punto de introducción local.',
    paragrafo7: 'Es importante notar que el estado de invasión es un atributo de la localidad de ocurrencia, no de las especies. De este modo, la misma especie puede comportarse como invasora en una localidad, estar registrada en otro sitio y haber sido detectada en la naturaleza.',
    paragrafo8: 'Cada información de ocurrencia recibida queda siempre vinculada a la fuente, o sea, a la persona que la proveyó y/o a un trabajo científico, incluyendo uno o más autores. Otros datos son referenciados en los campos descriptivos a la medida de lo posible. En caso de dudas, cuestionamientos o necesidad de incluir más información sobre algún dato, la fuente puede ser indicada o consultada. El vínculo entre las informaciones y los proveedores de datos reconoce la autoría y valora el trabajo y el apoyo de los colaboradores.',
    paragrafo8_en: '',
    paragrafo9_1: 'En el caso que tenga datos sobre especies exóticas invasoras para contribuir con la base de datos, por favor utilice la hoja Excel disponible en nuestro sitio web y envíela para ',
    paragrafo9_2: ' o',
    paragrafo9_3: ' contactémonos',
    paragrafo10: 'En caso de dudas o cuestionamientos, por favor envíe un correo ',
    paragrafo11: 'Los criterios básicos para la inclusión de especies en la base de datos son: (a) que estén presentes en Honduras y exista por lo menos uno o algunos registros de ocurrencia confiables; (b) tengan registros históricos de invasión en el propio país o en otra parte del mundo, generalmente bajo condiciones climáticas que facilitan la adaptación de la especie a algún tipo de condiciones climáticas en Honduras. Puede haber excepciones para especies exóticas invasoras que aún no expresan comportamiento invasor, pero sean consideradas de alto riesgo para la diversidad biológica hondureña y se registren en el país ya sea en cautiverio, acuarios, laboratorios, entre otros, así como con países fronterizos y con los que Honduras mantiene relaciones comerciales importantes, que aumente el riesgo inminente de introducción. Ninguna especie considerada invasora en el país es incluida sin que se reporte por lo menos un registro validado de ocurrencia.',
    paragrafo12: 'La base de datos contempla especies exóticas invasoras de todos los grupos biológicos con potencial de impacto sobre la diversidad biológica y/o sobre ambientes naturales o seminaturales. Las mismas especies pueden generar impactos sobre la economía, valores sociales y culturales, sobre la salud humana y animal. Sin embargo, el objetivo de esta base de datos es la conservación de la diversidad biológica y, por ello, no se incluyen en la base de especies exóticas que se comporten como malezas, plagas o patógenos de las personas y animales doméstico, asimismo, especies que no hayan sido detectadas todavía en la naturaleza o afectando a especies de la flora y fauna nativa, y que no tengan antecedentes internacionales en ese sentido.',
    paragrafo13: 'Cada vez que una especie es registrada en la base de datos, los datos complementarios son incluidos. A la medida que nuevos datos quedan disponibles, son incluidas nuevas informaciones y, principalmente, nuevas ocurrencias de la especie en el país. Estos datos son obtenidos de publicaciones técnicas y científicas, colaboradores directos que trabajan en el campo, administradores y funcionarios de áreas protegidas, así como, por observación y colecta de puntos directamente en el campo. La inclusión de coordinadas geográficas es obligatoria para todos los registros.',
    paragrafo14: 'Los datos se revisan y actualizan continuamente a partir de publicaciones o comunicaciones con colaboradores. Sin embargo, puede haber cuestionamiento sobre especies ingresadas en la base de datos por cuenta de la distribución natural o de la capacidad de invasión, en estos casos se busca apoyo de expertos en el grupo biológico específico para decidir si la especie debe ser removida. Algunas especies incluidas durante los primeros años de la base de datos fueron posteriormente removidas porque no había datos suficientes para atender a los criterios de inclusión o porque faltaban evidencias de invasión biológica. Esto puede ocurrir, por ejemplo, cuando se reconoce que una especie exótica es observada en un sitio por mucho tiempo, pero no existen evidencias de invasión a lo largo del tiempo, ni hay datos que confirmen el potencial de invasión en Honduras y en otras partes del mundo. En los casos de remoción de especies de la base de datos, los datos son almacenados separadamente y quedan en un listado de espera, para el caso que nuevas revisiones indiquen que debe ser incluida nuevamente.',
    paragrafo15: 'La confiabilidad de los datos es una de las principales preocupaciones de los administradores de este sistema. La validación de la información se evalúa en dos niveles principales: respecto de la correcta identificación de cada taxón y en referencia con la exactitud del sitio al que se refiere cada dato. Para eso se utilizan estándares y criterios consensuados con el resto de los sistemas que conforman la red de información sobre EEI.',
    paragrafo16: 'Criterios para validar la identidad taxonómica de cada taxón',
    marcador8: '¿El proveedor del dato (autor de la publicación o colector del espécimen) puede considerarse un especialista del grupo en cuestión o tiene una experiencia equivalente?',
    marcador9: '¿La ocurrencia está documentada mediante una fotografía que permite la identificación confiable del organismo en cuestión?',
    marcador10: '¿La ocurrencia está documentada en una publicación indexada y con evaluación de pares o en un documento técnico realizado o revisado por especialistas?',
    marcador11: '¿La información se corresponde con un ejemplar de herbario o con un espécimen depositado en una colección de museo?',
    marcador12: '¿La ocurrencia corresponde a una especie que ya estaba registrada para el país?',
    marcador13: '¿El grupo al que pertenece la especie tiene una taxonomía estable y bien conocida?',
    marcador14: '¿Las características de la especie permiten una determinación relativamente simple?',
    paragrafo17: 'Una respuesta positiva a al menos una de las preguntas 1, 2, 3 o 4 lleva directamente a la recomendación de considerar al dato como validado. En caso de responder negativamente a estas cuatro preguntas, la validación se obtendrá solamente con respuestas positivas a las tres últimas (5, 6 y 7).',
    paragrafo18: 'Criterios para validar la ubicación asociada a del sitio donde decimos que se detectó una especie',
    marcador15: '¿El dato incluye coordenadas geográficas? Se consideran tanto coordenadas tomadas directamente en el sitio de la observación como otras que correspondan a alguna referencia geográfica cercana (en el caso que esto se indique).',
    marcador16: '¿Se incluye una descripción del sitio que permite su identificación confiable?',
    marcador17: '¿La ocurrencia está documentada mediante una fotografía que permite la identificación confiable del sitio?',
    marcador18: '¿Las coordenadas geográficas provistas coinciden con la ubicación del sitio descripto y/o fotografiado?',
    marcador19: '¿La especie ya está citada para el país y, en ese caso, la localidad está incluida dentro de su rango de distribución conocido o en un sitio que resulta compatible con los sitios de presencia conocida y con su capacidad de dispersión?',
    marcador20: '¿Las condiciones ecológicas del sitio, inferidas en función de la localización indicada, resultan compatibles con los requerimientos generales de nicho de la especie?',
    paragrafo19: 'El dato se considera validado, desde el punto de vista de su ubicación geográfica, si:',
    marcador21: 'se responde de manera afirmativa a las preguntas 1, 2 y/o 3, y 4 (el dato incluye coordenadas geográficas, se brinda una referencia inequívoca del sitio y/o se provee una fotografía que permite la identificación confiable de lugar y las coordenadas provistas coinciden con la ubicación del sitio descripto o fotografiado, o,',
    marcador22: 'se asigna una respuesta positiva o negativa a la pregunta 1 (se brindan o no coordenadas geográficas), una respuesta negativa a las preguntas 2 y 3 (no se describe en detalle el sitio ni se adjunta una fotografía que permita ubicarlo), pero se responde de manera positiva a las preguntas 5 y 6 (la especie ya fue citada para el país y la localidad está incluida dentro de su rango de distribución conocido o en un sitio que resulta compatible con los sitios de presencia conocida y con su capacidad de dispersión, y las condiciones ecológicas del sitio resultan compatibles con los requerimientos generales de nicho de la especie).',
    paragrafo20: 'La localización se considerará no validada si: ',
    marcador23: 'se responde de manera positiva a la pregunta 1 y a las preguntas 2 y/o 3, pero negativa a la 4 (las coordenadas no coinciden con el sitio descripto o fotografiado), o.',
    marcador24: 'se responde de manera negativa a las preguntas 2 y 3, y negativa a al menos una de las preguntas 5 y 6 (el dato no cumple con una o con ninguna de estas dos condiciones: 1- La especie ya está citada para el país y, en ese caso, la localidad está incluida dentro de su rango de distribución conocido o en un sitio que resulta compatible con los sitios de presencia conocida y con su capacidad de dispersión, 2- el sitio reúne condiciones ecológicas compatibles con los requerimientos generales de nicho de la especie).',
    paragrafo21: 'Finalmente, el dato se considera validado en caso de pasar el análisis de validación de los dos criterios (taxonomía y ubicación).'
  },
  comiteCientifico: {
    cabecalhoTitulo: 'Comité científico',
    paragrafo1: 'La Base de Datos Nacional de Especies Exóticas Invasoras cuenta con el apoyo de un grupo de expertos en diversos campos del conocimiento para realizar la validación de los datos recibidos. Este grupo incluye a taxónomos o especialistas en grupos específicos de especies, así como a profesionales con experiencia en el campo cuyos conocimientos son muy relevantes para apoyar la verificación de la información.',
    paragrafo2: 'A partir de 2021 esta contribución se ha formalizado, con algunas personas invitadas a componer un Comité Científico para apoyar la validación de datos y la gestión de la Base de Datos Nacional de Especies Exóticas Invasoras. El papel del Comité Científico es proporcionar apoyo para la validación de datos, aportar datos de interés a la base de datos y ayudar a construir y mantener la red de colaboradores para permitir la actualización de la información y aumentar el número de contribuciones de datos.',
    paragrafo3: 'El Comité Científico se nombra por períodos de tres años, y los nombramientos pueden ser renovados o las personas sustituidas. El primer Comité Científico se formó en el curso de 2023 por:',
    marcador1: 'Lilian Ferrufino-Acosta, Bióloga M.Sc., Dr.rer.nat, Docente de la Universidad Nacional Autónoma de Honduras, Administradora de la Base de Datos;',
    marcador2: 'Katya Soler-Romero, M.Sc., Doctorado en Ciencia Biológicas, Departamento de Botánica, Instituto de Biología, posdoctorante de la Universidad Nacional Autónoma de México;',
    marcador3: 'Rina Fabiola Díaz, Bióloga, M.Sc. en Botánica, Asistente de investigación Herbario Paul C. Standley (EAP), Universidad Zamorano;',
    marcador4: 'Mayra Núñez Vallecillos, Bióloga, M.Sc., Doctorante, Asistente de en Coral Reef Alliance, colaborador de la base de datos;',
    marcador4b: 'Olvin Oyuela, Biólogo, M.Sc., Asistente técnico de la Universidad Nacional Autónoma de Honduras, colaborador de la base de datos;',
    marcador4c: 'Jorge Pérez, Biólogo, M.Sc., Asistente técnico de la Universidad Nacional Autónoma de Honduras, colaborador de la base de datos.',
    marcador4d: '',
    marcador4e: '',
    paragrafo4: 'Atribuciones del Comité Científico',
    marcador5: 'Decidir la inclusión de nuevas especies en la base de datos;',
    marcador6: 'Decidir la eliminación de especies por falta o inconsistencia de los datos;',
    marcador7: 'Apoyo para la validación de la información en la base de datos;',
    marcador8: 'Apoyo para la revisión y búsqueda de datos;',
    marcador9: 'Apoyo para la búsqueda y consulta a colaboradores y expertos para la contribución y validación de datos;',
    marcador10: 'Recomendaciones para mejorar la base de datos;',
    marcador11: 'Mejora continua del nivel de información.'
  },
  especiesInstitucional: {
    titulo: 'Busca por especies',
    paragrafo1: 'Busque aquí información detallada acerca de las especies exóticas invasoras y potencialmente invasoras presentes en la República de Honduras, los especialistas que las estudian, los proyectos dedicados a la comprensión de su comportamiento, sus efectos y las opciones de control, así como las publicaciones que se refieren a ellas.',
    paragrafo2_1: 'Si tuviera información complementaria lo invitamos a colaborar para mejorar el conocimiento sobre este problema ayudando a mantener completas y actualizadas estas bases de datos ',
    paragrafo2_2: 'haciendo click aquí',
    verTutorial: 'Abrir tutorial',
    paragrafo3: 'Por favor utilice el siguiente formato para citar información consultada en este sistema:',
    paragrafo4_1: 'Base de Datos Nacional de Especies Exóticas Invasoras en Honduras. Red Latino-Americana y del Caribe. Consultado en DD/MM/AAA.',
    paragrafo4_2: ' Acessado em __/__/____ (data)',
    tutorialTitulo1: 'Como citar la base de datos',
    tutorialDescricao1: 'Este es el formato de referencia para la citación de la base de datos en publicaciones.',
    tutorialTitulo2: 'Use los filtros',
    tutorialDescricao2: 'Aquí tenemos algunas opciones para filtrar contenido.',
    tutorialTitulo3: 'Use los filtros: seleccione datos',
    tutorialDescricao3: 'Elija filtros y haga combinaciones para seleccionar datos por lugar, forma biológica, uso, grupo taxonómico, etc.',
    tutorialTitulo4: 'Número de registros',
    tutorialDescricao4: 'Este es el número de especies exóticas invasoras en la base de datos resultante de las búsquedas con filtros.',
    tutorialDescricao5: 'Aquí puede elegir cuántos registros ver por página.',
    tutorialTitulo6: 'Lista de especies exóticas invasoras',
    tutorialDescricao6: 'Lista de todas las especies o resultados del uso de filtros. Haga clic sobre el nombre de la especie para ver los datos completos.',
    tutorialTitulo6_1: 'Exportar datos',
    tutorialDescricao6_1: 'Aquí puede filtrar datos y elegir el formato para exportar.',
    tutorialTitulo7: 'Taxonomía',
    tutorialDescricao7: 'Clasificación taxonómica de la especie seleccionada, nombres comunes, sinónimos, y una foto cuando fuera posible!',
    tutorialTitulo8: 'Ecología y uso',
    tutorialDescricao8: 'Descripción, biología y ecología de la especie seleccionada, de dónde viene, que ambientes invade y usos económicos.',
    tutorialTitulo9: 'Introducción y dispersión',
    tutorialDescricao9: 'Cómo llegó la especie al sitio donde es invasora, por qué fue introducida y cómo se dispersa a otros sitios.',
    tutorialTitulo10: 'Impactos',
    tutorialDescricao10: 'Ambientales, económicos, a la salud y culturales. Clasificación de impactos ambientales por el protocolo EICAT – IUCN.',
    tutorialTitulo11: 'Manejo',
    tutorialDescricao11: 'Medidas preventivas, de detección temprana, control mecánico, químico y biológico para la especie seleccionada.',
    tutorialTitulo12: 'Análisis de Riesgo',
    tutorialDescricao12: 'Resultados de análisis de riesgo para invasión biológica realizadas para la especie seleccionada.',
    tutorialTitulo13: 'Ocurrencias',
    tutorialDescricao13: 'Sitios, ambientes, áreas protegidas, estados donde la especie ocurre en el país y estadio de invasión – vea el mapa!',
    tutorialDescricao14: 'Lista de referencias usadas para compilar datos sobre la especie seleccionada y otras publicaciones sobre la especie.',
    tutorialTitulo15: 'Proyectos',
    tutorialDescricao15: 'Lista de proyectos de investigación y manejo que incluyen la especie seleccionada.'

  },
  colaboradoresInstitucional: {
    titulo: 'Colaboradorxs',
    paragrafo1: 'Lxs colaboradorxs incluyen a quienes aportan datos para este sistema de información, así como a quienes colectaron especímenes de museo o ejemplares de herbario o son autores de publicaciones que se utilizaron como fuentes de datos. ',
    paragrafo2: '',
    paragrafo3: 'Por favor utilice el siguiente formato para citar información consultada en este sistema:',
    paragrafo4_1: 'Base de Datos Nacional de Especies Exóticas Invasoras en Honduras. Red Latino-Americana y del Caribe. Consultado en DD/MM/AAA.',
    paragrafo4_2: ' Acessado em __/__/____ (data)'
  },
  acuaculturaInstitucional: {
    titulo: 'Acuacultura',
    paragrafo1: 'La acuicultura es la principal causa de introducción de especies de peces exóticos dulceacuícolas en Honduras. Desde el año de 1954, se han introducido al país al menos 10 especies de peces pertenecientes a seis familias distintas, nueve de ellas con objetivos productivos y uno con propósito pesquero.',
    paragrafo2: 'La piscicultura hondureña está dominada por el cultivo de dos especies de tilapia, la mossambica (Oreochromis mossambicus) y la nilótica (Oreochormis niloticus).',
    paragrafo3: 'Matamoros et al, (2009) reporta la primera en dos departamentos y una vertiente que desemboca al Pacífico mientras que la segunda se encuentra en 15 departamentos y 17 vertientes, 12 del Atlántico y cinco del Pacífico.',
    paragrafo3b: 'Las especies de peces exóticas ligadas a la acuicultura han logrado habitar cuerpos de agua dulce nacionales tras escaparse de los estanques, jaulas flotantes y demás unidades de producción.',
    paragrafo4: 'Tilapia - Oreochromis niloticus',
    paragrafo4b: 'Foto: Jorge Alberto Perez Antunez'
  },
  acuariofiliaInstitucional: {
    titulo: 'Acuariofilia',
    paragrafo1: 'No se ha documentado el origen de la acuariofilia en Honduras, ni las primeras especies ornamentales introducidas al país, pero es común encontrar tiendas de mascotas, páginas web y entusiastas que se dedican a la compra y venta de peces exóticos en varias ciudades a nivel nacional.',
    paragrafo2: 'La Ley General de Pesca y Acuicultura de Honduras no cuenta con un reglamento para la introducción y distribución de peces ornamentales por lo que dicha actividad se realiza en su mayoría de manera ilegal, con excepción de algunos acuarios privados que realizan un trámite con el Servicio Nacional de Sanidad Agropecuaria y las alcaldías donde se ubican.',
    paragrafo3: 'Cabe destacar que el acuerdo ministerial 0740-2019 de la Secretaría de Energía, Recursos Naturales, Ambiente y Minas cuenta con una tabla de categorización de proyectos acuícolas donde los acuarios se ubican en la categoría 3 por su impacto al medio ambiente.',
    paragrafo4: '',
    marcador1: '',
    marcador2: '',
    marcador3: '',
    paragrafo5: 'Acara azul - Andinoacara pulcher',
    paragrafo5b: 'Foto: Gabriel González',
    paragrafo6: 'Betta, luchador de siam - Betta splendens',
    paragrafo6b: 'Foto: Gabriel González',
    paragrafo7: 'Pez dorado - Carassius auratus',
    paragrafo7b: 'Foto: Gabriel González',
    paragrafo8: 'Pez cebra - Danio rerio',
    paragrafo8b: 'Foto: Gabriel González',
    paragrafo9: 'Escalar, pez ángel - Pterophyllum scalare',
    paragrafo9b: 'Foto: Gabriel González',
    paragrafo10: 'Tetra cardenal - Paracheirodon axelrodi',
    paragrafo10b: 'Foto: Gabriel González',
    paragrafo11: 'Tilapia - Oreochromis sp',
    paragrafo11b: 'Foto: Gabriel González'
  },
  especiesMarinoCosterasInstitucional: {
    titulo: 'Especies marino-costeras',
    paragrafo1: 'Las especies invasoras marino-costeras son difíciles de detectar, pues generalmente son introducidas por medio de actividad humana como lo es la descarga de agua de lastre o vienen adheridas a los cascos de los barcos. Otra vía de introducción es por corrientes marinas, como es el caso de la especie del Indo pacífico el pez león Pterois volitans que fue introducido como pez de acuario en Florida, Estados Unidos, de donde escapó y se dispersó para todo el Mar de Caribe, llegando al Océano Atlántico. Otras especies son introducidas por el mal manejo de cultivos de especies exóticas, como camarones y peces, provocando su liberación al medio ambiente.',
    paragrafo2: 'Estas especies invasoras en los nuevos hábitats suelen tener éxito en su desarrollo debido, tanto a las condiciones ambientales favorables como también a la carencia de depredadores naturales que controlen su población. Las especies invasoras pueden provocar un desequilibrio ecológico al alimentarse de especies nativas, y al competir por espacio y comida con las especies locales, desplazando o disminuyendo las poblaciones nativas generando impacto a la biodiversidad, los ecosistemas y al bienestar humano.',
    paragrafo3: '',
    paragrafo3b: '',
    paragrafo4: 'Pez león - Pterois volitans',
    paragrafo4b: 'Foto: Mayra Vallecillos',
    paragrafo5: 'Musa velutina - banano rosa',
    paragrafo5b: 'Foto: Eduardo Chacón-Madrigal',
    paragrafo6: 'Nylanderia fulva - hormiga loca',
    paragrafo6b: 'Foto: Bentleypkt',
    paragrafo7: 'Syzygium jambos - manzana rosa',
    paragrafo7b: 'Foto: Eduardo Chacón-Madrigal',
    paragrafo8: 'Ulex europaeus - chucero',
    paragrafo8b: 'Foto: Eduardo Chacon-Madrigal'
  },
  ornamentalesInstitucional: {
    titulo: 'Plantas ornamentales',
    paragrafo1: 'En Honduras se registran cerca de 700 especies exóticas, que representan 305 casuales y 427 naturalizadas (Rojas-Sandoval et al., 2022). Estas constituyen el 6.4% de la flora de Honduras. Por décadas la jardinería ha empleado especies exóticas con sucesivas introducciones. Estas especies son usadas en jardines de casa, paseos de bulevares, parques urbanos, plazas, centros de visitantes de algunas áreas protegidas, entre otros, con el objetivo de embellecer estos espacios, proporcionar sombra, alimento para animales silvestres, recursos florales para insectos, aves y murciélagos, refugio de flora y fauna.',
    paragrafo2: 'La introducción de estas especies con frecuencia conlleva que se escapen de estos espacios y que colonicen en áreas donde habitan especies nativas, logrando su desplazamiento. Un ejemplo, es la orquídea africana (Oeceoclades maculata), lengua de suegra (Sansevieria trifasciata) registradas en algunas áreas protegidas, del mismo modo que la llama de bosque (Spathodea campanulata), casuarina (Casuarina equisetifolia), bambú (Bambusa vulgaris), china (Impatiens balsamina), ojo de pájaro (Thunbergia alata) ampliamente distribuidas e invasoras en el Neotrópico.',
    paragrafo3: '',
    paragrafo4: 'Corona de cristo - Euphorbia milii',
    paragrafo4b: 'Foto: Herbario TEFH',
    paragrafo5: 'Bastón del emperador - Etlingera elatior',
    paragrafo5b: 'Foto: Herbario TEFH',
    paragrafo6: 'Orquidea africana - Oeceoclades maculata',
    paragrafo6b: 'Foto: Herbario TEFH',
    paragrafo7: 'Llama del bosque - Spathodea campanulata',
    paragrafo7b: 'Foto: Herbario TEFH',
    paragrafo8: 'Llama del bosque - Spathodea campanulata',
    paragrafo8b: 'Foto: Herbario TEFH',
    paragrafo9: 'Almendra - Terminalia catappa',
    paragrafo9b: 'Foto: Herbario TEFH'
  },
  plantasForrajerasInstitucional: {
    titulo: 'Plantas forrajeras',
    paragrafo1: 'Las especies forrajeras en Honduras han sido usadas en su mayoría para alimento del ganado vacuno, aves de corral, cabras, cerdos, entre otros, favoreciendo la producción y reproducción de los animales domésticos. La mayoría de las especies pertenecen a las familias de los pastos (Poaceae) y la soya y frijoles (Fabaceae).',
    paragrafo2: 'En nuestro país, varias instituciones públicas y privadas, organizaciones no gubernamentales han propuesto especies forrajeras con el propósito de alimento de animales. Estas especies son empleadas por su rápido crecimiento, ya que la mayoría son herbáceas y están adaptadas a climas secos, toleran suelos inundados, poca competencia con malezas, alta digestibilidad, alta palatabilidad, alto valor nutritivo, entre otros.',
    paragrafo2a: 'Sin embargo, estas especies introducidas se han establecido o colonizado en áreas abiertas o áreas deforestadas que se encuentran en los primeros estadios de sucesión.',
    paragrafo2b: 'También las especies forrajeras son usadas para cercas vivas, protección de taludes, rompevientos, control de erosión, cobertura, recuperación de suelos, barbecho, abono verde, tintes, melíferas, recurso floral para polinizadores, sistemas agroforestales, asimismo ornamentales como el maní forrajero Arachis pintoi, medicinales como manzana de Adán Clitoria ternatea, frijol abono Mucuna pruriens y frijol mantequilla Canavalia ensiformis como insecticida.',
    paragrafo3: 'Pasto guinea - Megathyrsus maximus',
    paragrafo3b: 'Foto: Arles Garcia',
    paragrafo4: 'Pasto guinea - Megathyrsus maximus',
    paragrafo4b: 'Foto: Arles Garcia'
  },
  mascotesInstitucional: {
    titulo: 'Mascotas y peces ornamentales',
    paragrafo1: 'El comercio de mascotas y peces ornamentales es una de las vías de introducción de especies exóticas más importantes en cuanto al volumen y diversidad de especies. Las personas compran reptiles, aves y peces a través de sitios o comercios especializados. Aunque la mayoría de organismos se mantiene en cautiverio, algunos son liberados a la naturaleza cuando ya las personas no los quieren o no pueden mantenerlos y otros escapan de manera accidental. Los organismos liberados tiene un chance para de establecerse si encuentran las condiciones adecuadas y pueden competir con especies nativas o transmitirle enfermedades, lo que puede tener un impacto negativo en el ecosistema.',
    paragrafo2: 'No solamente se exportan las especies que se quieren tener como mascotas sino que hay asociado a esta actividad un comercio de otras especies que se utilizan como alimento y otras (ej.plantas acuáticas) que se utilizan para formar el hábitat en las que se mantienen.',
    paragrafo3: '',
    paragrafo4: 'Carassius auratus - carpa',
    paragrafo4b: 'Foto: Felipe Aira',
    paragrafo5: 'Lonchura malacca - munia tricolor',
    paragrafo5b: 'Foto: Shino Jacob Koottanad',
    paragrafo6: 'Poecilia reticulata - Guppy',
    paragrafo6b: 'Foto: Vincent Eisfeld ',
    paragrafo7: 'Xiphophorus variatus - Plati variado',
    paragrafo7b: 'Foto: Marrabbio'
  },
  plantasOrnamentaisInstitucional: {
    titulo: 'Plantas ornamentales',
    paragrafo1: 'Las plantas ornamentales son aquellas que los humanos utilizamos con el propósito de decorar jardines, parques, proyectos de paisajismo, urbanismo o diseño de interiores y floristería. El uso de plantas ornamentales representa una actividad económica creciente y millonaria en todo el mundo. Debido al suministro globalizado y a la demanda de plantas ornamentales, existe una alta movilización de especies entre países. Sin embargo, esta actividad también causa daños ecológicos. Actualmente, el comercio de plantas ornamentales es la principal fuente de plantas exóticas potencialmente invasoras. Más del 50% de todas las especies de plantas invasoras y 85% de las especies leñosas invasoras fueron originalmente introducidas como ornamentales.',
    paragrafo2: 'Los daños ecológicos pueden ser irreversibles y ocasionar grandes pérdidas económicas. La regulación del comercio de plantas ornamentales representa un enorme reto para la legislación y las políticas de sostenibilidad de países de todo el mundo.',
    paragrafo3: '',
    paragrafo4: 'Impatiens hawkeri - china',
    paragrafo4b: 'Foto: Eduardo Chacon Madrigal',
    paragrafo5: 'Spathodea campanulate - Llama del bosque',
    paragrafo5b: 'Foto: Eduardo Chacon Madrigal',
    paragrafo6: 'Thunbergia alata - ojo de poeta',
    paragrafo6b: 'Foto: Eduardo Chacon Madrigal'
  },
  projetosInstitucional: {
    titulo: 'Proyectos',
    paragrafo1: 'En esta sección se incluye información acerca de los proyectos actuales o anteriores enfocados en el estudio y el manejo de las especies exóticas invasoras y potencialmente invasoras presentes en Honduras.',
    paragrafo2_1: 'Si participa o participó en algún proyecto relacionado con estas especies, por favor ayude a incorporarlo enviando la información correspondiente a',
    paragrafo2_2: 'enviando informação sobre o projeto ',
    paragrafo2_3: 'para que podamos registrarlo. Esperamos que la divulgación beneficie su trabajo.',
    paragrafo3: 'Para citar datos del sistema utilice el siguiente formato:',
    paragrafo4_1: 'Base de Datos Nacional de Especies Exóticas Invasoras en Honduras. Red Latino-Americana y del Caribe. Consultado en DD/MM/AAA.',
    paragrafo4_2: 'Acessado em __/__/____ (data)'
  },
  referenciasInstitucional: {
    titulo: 'Referencias',
    paragrafo1: 'Las publicaciones e informes técnicos citados aquí fueron utilizados para sumar información acerca de las especies presentes en el territorio nacional, su comportamiento y efectos. Algunas incluyen datos acerca de las opciones de manejo de las distintas especies.',
    paragrafo2: 'Si es autor o conoce publicaciones en la materia que aún no están incluidas en esta sección, por favor ayude a incorporarlas enviando las citas correspondientes a',
    paragrafo3: 'Contribuya con datos',
    paragrafo4: 'Por favor utilice el siguiente formato para citar información consultada en este sistema:',
    paragrafo5_1: 'Base de Datos Nacional de Especies Exóticas Invasoras en Honduras. Red Latino-Americana y del Caribe. Consultado en DD/MM/AAA.',
    paragrafo5_2: 'Consultado en __/__/____ (fecha)'
  },
  usoFlorestalInstitucional: {
    titulo: 'Uso forestal',
    paragrafo1: 'Honduras posee un 56.06% son tierras con cobertura forestal, y un 18% dominado por un bosque de coníferas.',
    paragrafo2: 'En Honduras, se identifican algunas especies introducidas para usos maderables. Varias de estas especies son introducidas por su rápido crecimiento y para uso en sistemas agroforestales, como la limba Terminalia superba y la caoba africana Khaya senegalensis, que, si bien aún no invaden áreas naturales, tienen el potencial de expandirse por fuera de las plantaciones. En las partes altas de Lempira, con terrenos con pendientes muy pronunciadas se realiza el sistema Quesungual, que tiene como objetivo combinar especies forestales nativas con especies frutales introducidas.',
    paragrafo3: 'En Honduras, algunos proyectos de reforestación liderados por instituciones públicas y privadas, organizaciones no gubernamentales proponían el uso de especies exóticas. Sin embargo, la introducción de especies invasoras trae, como consecuencia, el desplazamiento de especies nativas y endémicas en los ecosistemas, asimismo amenazando los recursos hídricos porque son especies de rápido crecimiento.',
    paragrafo4: 'Limba - Terminalia superba',
    paragrafo4b: 'Foto: Alchetron Free Social Encyclopedia'
  },
  contatoInstitucional: {
    cabecalhoTitulo: 'Contacto',
    contato: 'Contacto',
    p1: 'Complete todos los campos del formulario siguiente:',
    nome: 'Nombre',
    email: 'Correo electrónico',
    instituicao: 'Institución',
    mensagem: 'Mensaje',
    enviar: 'Enviar'
  },
  colaboreDados: {
    cabecalhoTitulo: 'Contribuya con datos',
    paragrafo1: 'Nuestras bases de datos se mantienen actualizadas gracias a los aportes de especialistas y observadores de la naturaleza distribuidos en todo el territorio nacional. Antes de publicarse, la información provista es validada siguiendo criterios acordados a nivel regional.',
    paragrafo2: 'Usted puede aportar datos sobre especies aún no incluidas en este sistema o sobre nuevas localidades de ocurrencia descargando nuestra app (Invasoras AR) o enviando un e-mail a',
    paragrafo2b: 'Además, en este email se puede informar acerca de publicaciones y proyectos sobre invasiones biológicas en Honduras. Los proveedores de datos que realizan aportes de manera regular reciben un usuario y una contraseña para la carga de información.',
    paragrafo3: 'Ayúdenos a enfrentar el desafío de las especies exóticas invasoras sobre la base de información completa, actualizada y confiable!',
    paragrafo4: 'Muchas gracias!'
  }
}
