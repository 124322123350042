export const en = {
  message: {
    requiredField: 'Required field',
    noRecords: 'No record found!',
    confirmDel: 'Are you sure you wish to delete?',
    confirmApproval: 'Are you sure you wish to approve this occurrence?',
    noResultsFor: 'No results for'
  },
  login: {
    username: 'User',
    password: 'Password',
    login: 'Login',
    entrar: 'Log in',
    descricao: 'Enter your access data in the form below:'
  },
  exportarFicha: {
    author: 'author',
    biodiversity_impact: 'biodiversity_impact',
    chemical_control: 'chemical_control',
    class: 'class',
    common_name: 'common_name',
    control_biological: 'control_biological',
    data_bibliography: 'data_bibliography',
    economic_impact: 'economic_impact',
    economic_use: 'economic_use',
    family: 'family',
    health_impact: 'health_impact',
    intro_data: 'intro_data',
    intro_description: 'intro_description',
    invasion_preferential_environments: 'invasion_preferential_environments',
    kingdom: 'kingdom',
    location: 'location',
    morphophysiology_description: 'morphophysiology_description',
    native_distribution_area: 'native_distribution_area',
    natural_environment: 'natural_environment',
    oorder: 'oorder',
    origin: 'origin',
    phyllum: 'phyllum',
    physical_control: 'physical_control',
    prevention: 'prevention',
    scientific_name: 'scientific_name',
    social_impact: 'social_impact',
    species_id: 'species_id',
    voc_cause_introduction: 'voc_cause_introduction',
    voc_diet: 'voc_diet',
    voc_dispersal_routes: 'voc_dispersal_routes',
    voc_dispersion_vectors: 'voc_dispersion_vectors',
    voc_form: 'voc_form',
    voc_introduction_type: 'voc_introduction_type',
    voc_reproduction: 'voc_reproduction',
    voc_spread: 'voc_spread',
    world_invasive_place: 'world_invasive_places'
  },
  label: {
    voltar: 'Back',
    outro: 'Other',
    filtros: 'Filters',
    acesseBaseDados: 'Access the database',
    conhecaNossaRede: 'Latin American network',
    baseDadosNacional: 'National Database',
    politicaDeDados: 'Data policy',
    comiteCientifico: 'Scientific committee',
    colabore: 'Collaborate',
    especiesMarinhas: 'Marine species',
    peixesAquario: 'Aquarium fishes',
    peixesAquicultura: 'Aquaculture fishes',
    pescaDesportiva: 'Sport fishing',
    pets: 'Pets',
    plantasForrageiras: 'Forage plants',
    plantasOrnamentais: 'Ornamental plants',
    usoFlorestal: 'Forestry',
    tutorial: 'Tutorial',
    especies: 'Species',
    colaboradores: 'Collaborators',
    referencias: 'References',
    projetos: 'Projects',
    saibaMais: 'More info',
    contida: 'Contained',
    casual: 'Casual',
    estabelecida: 'Established',
    invasora: 'Invasive',
    exibirListagem: 'View List',
    concluir: 'Complete',
    fechar: 'Close',
    próximo: 'Next',
    anterior: 'Former',
    clickAqui: 'Click here',
    numRegistrosEncontrados: 'Number of records found:',
    exibir10Reg: 'Display 10 records',
    exibir25Reg: 'Display 25 records',
    exibir50Reg: 'Display 50 records',
    exibir100Reg: 'Display 100 records',
    limpar: 'Clear',
    buscar: 'Search',
    addColaborador: 'Add collaborator',
    nome: 'Name',
    sobrenome: 'Last name',
    aprovarColaborador: 'Approve collaborator',
    salvarColaborador: 'Save collaborator',
    titulo: 'Title',
    cargo: 'Position',
    especialidade: 'Expertise',
    instituicao: 'Institution',
    endereco: 'Address',
    estado: 'State',
    cidade: 'City',
    pais: 'Country',
    email: 'E-mail address',
    telefone: 'Telephone 1',
    telefone2: 'Telephone 2',
    websiteURL: 'Website URL',
    midiaSocial1: 'Social media 1',
    midiaSocial2: 'Social media 2',
    midiaSocial3: 'Social media 3',
    observacoesAdicionais: 'Additional observations',
    adicionar: 'Add',
    editando: 'Editing',
    acoes: 'Actions',
    usuarixs: 'Users',
    adicionarUsuario: 'Add user',
    descricao: 'Description',
    ativo: 'Active',
    sim: 'Yes',
    nao: 'No',
    salvarUsuarix: 'Save user',
    senha: 'Password',
    observacoes: 'Observations',
    grupos: 'Groups',
    basico: 'Basic',
    administrator: 'Administrator',
    taxonomia: 'Taxonomy',
    nomeCientifico: 'Latin name',
    adicionarEspecie: 'Add species',
    genero: 'Genus',
    familia: 'Family',
    ordem: 'Order',
    classe: 'Class',
    phylumDivisao: 'Phylum / Division',
    reino: 'Kingdom',
    phylum: 'Phylum',
    origem: 'Origin',
    descricaoInvalida: 'Invalid description',
    selecioneFamilia: 'Please select a Family.',
    selecioneOrdem: 'Please select an Order.',
    selecioneClasse: 'Please select a Class.',
    selecioneDivisao: 'Please select a Phylum.',
    selectioneReino: 'Please select a Kingdom.',
    selectioneGenero: 'Please select a Genus.',
    addReferencia: 'Add reference',
    autoresEX: 'Authors (ex. ZILLER, S. R.; DECHOUM, M. S.)',
    aprovarReferencia: 'Approve reference',
    salvarReferencia: 'Save reference',
    autorxsEX: 'Authors (ex. ZILLER, S. R.; DECHOUM, M. S.)',
    autores: 'Authors',
    local: 'Location (Ex. Salvador, BA  /  África do Sul: Cidade do Cabo)',
    localFiltro: 'Location',
    edicao: 'Edition (ex. 2 ed.)',
    editora: 'Editor',
    revista: 'Journal',
    anoPublicacao: 'Publication year',
    meioDivulgacao: 'Publication means',
    volume: 'Volume (ex. v. 3)',
    numero: 'Number (ex. n. 28)',
    pagina: 'Pages (ex. p. 25-34 / 245p.)',
    url: 'DOI / URL',
    palavraChave: 'Keywords',
    anoPublic: 'Publication year',
    addProjeto: 'Add project',
    aprovarProjeto: 'Approve project',
    salvarProjeto: 'Save project',
    contato: 'Contact',
    areaGeografica: 'Geographic range',
    objetivos: 'Objectives',
    breveDescricao: 'Brief description',
    dataInicio: 'Start date',
    dataTermino: 'End date',
    comentarios: 'Comments',
    anoInicio: 'Start year',
    anoTermino: 'End year',
    ocorrencias: 'Occurrences',
    exportarDados: 'Export data',
    addOcorrencia: 'Add occurrence',
    visualizarOcorrencia: 'View occurrence',
    alterarOcorrencia: 'Change occurrence',
    ocorrenciasVinculadas: 'Linked occurrences',
    areaProtegida: 'Protected area',
    especie: 'Species',
    aprovarOcorrencia: 'Approve occurrence',
    salvarOcorrencia: 'Save occurrence',
    colaborador: 'Collaborator',
    municipio: 'Municipality',
    municipioSede: 'Municipality (city)',
    municipioCentroide: 'Municipality (centroid)',
    referenciaLocal: 'Local reference',
    referencia: 'Reference',
    latitude: 'Latitude',
    longitude: 'Longitude',
    coordenadasCorrespondem: 'The coordinates correspond to',
    distanciaAproximada: 'Approximate distance',
    ambiente: 'Environment',
    baciaHidro: 'Basin',
    descricaoInvasao: 'Description of the invasion',
    abundancia: 'Abundance',
    situacaoPopulacional: 'Population status',
    manejo: 'Management',
    dataEntrada: 'Entry date',
    dataObservacao: 'Observation date (ex. 2020 ou 13/05/2020)',
    validado: 'Validated',
    especieMuseuHerbario: 'Museum or herbarium specimen',
    selecioneTipo: 'Select the type',
    herbario: 'Herbarium',
    museu: 'Museum',
    numeroExemplaInstituicao: 'Specimen number (Institution)',
    numeroExemplarColetor: 'Specimen number (Collector)',
    selecioneOpcao: 'Select an option',
    pointList1: 'A point of occurrence of one or more individuals of the species',
    pointList2: 'The center of a group of individuals of the species',
    pointList3: 'A point of occurrence at an approximate distance of:',
    coordinatesSourceList1: 'A - A political or administrative unit',
    coordinatesSourceList2: 'B - The actual site of occurrence',
    ultimoRegistroBaseDados: 'Latest records in database',
    ultimosAcessos: 'Latest access',
    fichaTecnicaEspecie: 'Species factsheet',
    fichaTecnicaEspecieMMA: 'Species factsheet MMA version 1',
    fichaTecnicaEspecieMMA2: 'Species factsheet MMA version 2',
    invasoras: 'Invasive',
    contidasBrasil: 'Contained',
    ausentesBrasil: 'Absent',
    deficiencia: 'Data deficient (DD)',
    formatosDisponiveisExportacao: 'Available formats for export:',
    biologiaEcologia: 'Biology and ecology',
    usoEconomico: 'Economic use',
    usoEconomicoDescricao: 'Economic use - description',
    invasaoBiologica: 'Biological invasion',
    impactos: 'Impacts',
    habitat: 'Habitat',
    nomeCientificoSinonimos: 'Scientific name + synonyms',
    nomesPopulares: 'Common names',
    reproducao: 'Reproduction',
    dispersao: 'Dispersal',
    dieta: 'Diet',
    formaBiologica: 'Habit',
    ambientesPreferenciaisInvasao: 'Habitats more susceptible to invasion',
    tipoIntroducao: 'Introduction type',
    causaIntroducao: 'Cause of introduction',
    localIntroducao: 'Place of introduction',
    anoIntroducao: 'Year of introduction',
    marinho: 'Marine',
    dulcicola: 'Freshwater',
    terrestre: 'Terrestrial',
    validarEspecie: 'Validate species',
    salvarEspecie: 'Save species',
    data: 'Date',
    confiabilidade: 'Confidence level',
    resultadosAnalisesRisco: 'Risk assessment results',
    risco: 'Risk',
    areaOrigem: 'Area of origin',
    areaDistribuicaoNatural: 'Native range',
    ambientesNaturais: 'Natural habitats',
    descricaoEspecie: 'Species description',
    ambientesPreferenciaisInvasaoDescricao: 'Habitats more susceptible to invasion - description',
    outrosLocaisOndeEspecieInvasora: 'Other places where the species is invasive',
    impactosEcologicos: 'Ecological impacts',
    impactosEconomicos: 'Economic impacts',
    impactosSociais: 'Social impacts',
    impactosSaude: 'Health impacts',
    categoriaEICAT: 'EICAT category',
    mecanismosImpactoEICAT: 'EICAT impact mechanisms',
    nivelConfiancaAvaliacaoEICAT: 'EICAT assessment confidence level',
    referenciaEICAT: 'EICAT reference',
    dataEICAT: 'EICAT date (e.g. 2021)',
    categoriaSEICAT: 'SEICAT category',
    mecanismosImpactoSEICAT: 'SEICAT impact mechanisms',
    nivelConfiancaAvaliacaoSEICAT: 'SEICAT assessment confidence level',
    referenciaSEICAT: 'SEICAT reference',
    dataSEICAT: 'SEICAT date (e.g. 2021)',
    digitarSomenteAno: 'Enter year only - e.g. 2021',
    viasCDBCategorias: 'CDB pathways - categories',
    viasIntroducao: 'Pathways of introducion and spread',
    viasVetoresCDBSubcategorias: 'CDB pathways - subcategories',
    vetoresIntroducao: 'Vectors of introducion and spread',
    introducao: 'Introduction',
    adicionarIntroducao: 'Add introduction',
    ano: 'Year',
    descricaoIntroducao: 'Introduction description',
    salvar: 'Save',
    cancelar: 'Cancel',
    excluir: 'Delete',
    medidasPreventivas: 'Prevention measures',
    medidasPreventivasMMA: 'Prevention measures MMA',
    medidasDeteccaoPrecoce: 'Early detection measures',
    controleMecanico: 'Mechanical control',
    controleQuimico: 'Chemical control',
    controleBiologico: 'Biological control',
    distribuicaoPais: 'Distribution in the country',
    vejaMapaOcorrencias: 'See occurrence map',
    ecologiaUso: 'Ecology and use',
    introducaoDispersao: 'Introduction and spread',
    analiseRisco: 'Risk analysis',
    nomeComum: 'Common name',
    foto: 'Photograph',
    credito: 'Credit',
    sinonimos: 'Synonyms',
    autor: 'Author',
    subespecie: 'Subspecies',
    autorSubespecie: 'Author of subspecies',
    pendenteAnalise: 'Analysis pending',
    cancelarVoltar: 'Cancel / Back',
    versaoAnterior: 'Former version',
    versaoAtual: 'Current version',
    colaboradorxs: 'Collaborators',
    consultaTaxonomiaListaCompletaEspecies: 'Taxonomy - Complete list of species',
    consultaEspeciesOrigemAmbientes: 'Species, origin, habitats susceptible to invasion, impacts',
    consultaEspeciesManejo: 'Species and management',
    consultaParaGerarFichaTecnica: 'Species factsheet without places of occurrence',
    consultaEspeciesOcorrencias: 'Species and places of occurrence',
    exportarDadosConsultas: 'Export data',
    ocorrenciasApp: 'Occurrences app',
    dataCadastro: 'Registration date',
    criadoPor: 'Created by',
    nivelCerteza: 'Confidence level',
    byApp: 'From mobile app',
    verDados: 'View data',
    appuser: 'App user',
    profissao: 'Profession',
    colobadorJaCadastrado: 'Collaborator already registered',
    especialidadeApp: 'Expertise app',
    cep: 'Zip code',
    categoriaCDB: 'CBD Category',
    subCategoriaCDB: 'CDB Subcategory',
    mensagemEnviada: 'Message sent successfully.'
  },
  home: {
    destaque1: 'Welcome to the National Database of Invasive Alien Species!',
    destaqueDescricao1: 'This database system receives contributions from people committed to the conservation of biodiversity. We invite you to visit our page!',
    destaque2: 'Since 2005 documenting invasive alien species and sharing the information online!',
    destaqueDescricao2: 'This database system was developed and is kept up-to-date with national and international resources and has a supporting Scientific Committee.',
    destaque3: 'What does this information system include?',
    destaqueDescricao3: 'We manage four interconnected databases on species, references, collaborators and projects.',
    destaque4: 'How does it work?',
    destaqueDescricao4: 'You can consult about invasive and potentially invasive exotic species, specialists, projects and bibliography in the Republic of Honduras.',
    titulo: 'Search species, filter and export data',
    conteudo: 'Here you will find data on the native range of invasive alien species, ecological and biological characteristics, types of habitat invaded, where they are invasive in the world and in Costa Rica, including a map of places of occurrence, history of introduction and use, vectors and pathways of introduction and spread, information on management and references. Filters are available to select and export data to different formats.',
    conteudoEspecies: 'Species lists, ecological traits, native range, where the species are invasive in the world, vectors and pathways, risk assessment, uses, management options, places of occurrence in Costa Rica, references.',
    conteudoColaboradores: 'Registry of people who have contributed data to the database. If you need to find an expert or know more about a place of occurrence, look for contacts here.',
    conteudoReferencias: 'Complete list of references used as a basis for the data fields on invasive alien species.',
    conteudoProjetos: 'Invasive alien species research and management projects in Costa Rica. If you are working on a project, please let us know so we can register it.',
    titulo1: 'Discover invasive alien species',
    conteudo1: 'Here you will find lists of invasive alien species in Honduras organized by groups that indicate the reason for their introduction. Each page has an explanation on the group and links for additional sources of information.'
  },
  areaParceiros: {
    titulo: 'Latin America and Caribbean Invasive Alien Species Database Network:',
    argentina: 'Argentina',
    paraguai: 'Paraguay',
    uruguai: 'Uruguay'
  },
  redeBaseDeDados: {
    cabecalhoTitulo: 'Latin-American and Caribbean Network',
    paragrafo1: 'This Database Network originates in the year 2005 as I3N (IABIN Invasives Information Network), a thematic network on invasive alien species then developed as part of the InterAmerican Biodiversity Information Network established by governments of countries in the Americas in 2001.',
    paragrafo2: 'Each country had a Lead for the network in charge of developing a national database and sharing information on invasive species. The people in charge of the national databases are:',
    paragrafo2_1: '',
    marcador1: 'Argentina: Dr. Sergio M. Zalba, Professor, Universidad Nacional del Sur, Bahía Blanca (since 2003);',
    marcador2: 'Brazil: Dr. Silvia R. Ziller, Founder and Executive Director of the Horus Institute for Environmental Conservation and Development (since 2004);',
    marcador3: 'Chile: Dr. Aníbal Pauchard, Professor, Facultad de Ciencias Forestales, Universidad de Concepción;',
    marcador4: 'Costa Rica: PhD. Eduardo Chacón-Madrigal, Professor at the University of Costa Rica. Researcher at the Biodiversity and Tropical Biology Research Center. Co-coordinator of Flora Invasive and Naturalized of Central America;',
    marcador5: 'Ecuador: Dr. Diego Inclán, Executive Director of the National Institute of Biodiversity (since 2021) and Francisco José Prieto Albuja;',
    marcador5_1: 'Honduras: Dra Lilian Ferrufino, Universidad Nacional Autónoma de Honduras;',
    marcador5_2: 'Jamaica: Dr. Suzanne Davis, Clearing-House Mechanism, Institute of Jamaica (since 2004).',
    marcador5_3: 'Paraguay: Hugo Fernando del Castillo, Guyra Paraguay Bird Conservation (since 2006);',
    marcador5_4: 'Uruguay: Dr. Ernesto Brugnoli, Professor, Universidad de la República, Montevideo (since 2005), and M.Sc. Marcelo Iturburu, Coordinator of the Invasive Alien Species Committee of the Ministry of Environment (since 2018).',
    paragrafo3: 'IABIN received funding from a World Bank (GEF) project between 2004 and 2011, when it was terminated. Despite the closing of IABIN, some of the network Leads continued to develop and update the national databases (Brazil, Argentina, Uruguay, Paraguay and Jamaica).',
    paragrafo4: 'In 2021, the four South American countries received funding from the Bio-Bridge Initiative of the Convention on Biological Diversity to revitalize the Argentina, Brazil, Paraguay, and Uruguay databases. The interface was redesigned, new functions were added, and the programming was totally redone.',
    paragrafo5: 'The database developers are:',
    marcador6: 'The Programmer João Scucato, from Curitiba, Brazil, who developed the first version in Microsoft Access, 2004-2005;',
    marcador7: 'The Programmer Alejandro Moreno, from Argentina, who has provided support to the databases since 2006, and rescued data from Paraguay and Uruguay for this new version. He also developed the',
    marcador7_1: 'Common Platform',
    marcador7_2: 'for the South American countries in 2021;',
    marcador8: 'The Web Designer Rafael Moura and Programmer Thiago Lôbo, through',
    marcador8_1: 'Plataforma Vibbra',
    marcador8_2: 'in Florianópolis, Brazil; ',
    marcador9: 'The programmer Leonardo Rotondano, in charge of developing the cell phone application for invasive species occurrences.',
    paragrafo6: 'We hope to expand the network once again to include more countries in Latin America and the Caribbean, making more data on invasive alien species available for management and policy, public knowledge and on-site field work.',
    paragrafo7: 'Latin America and Caribbean Invasive Alien Species Database Network:',
    paragrafo8: '',
    paragrafo9: ''
  },
  baseDadosNacional: {
    cabecalhoTitulo: 'Invasive Alien Species Database',
    titulo: 'The National Database of Invasive Alien Species',
    paragrafo1: 'This information platform was developed in 2004 by The Horus Institute in Brazil and the Universidad Nacional del Sur in Bahía Blanca, Argentina (Dr. Sergio Zalba), as part of an invasive species information network then known as I3N (IABIN Invasives Information Network). The network was then coordinated by Andrea Grosse, a Biologist who dedicated much time and effort to build the network, supported the development of the database structure and of several other products such as risk analysis protocols, manuals and training courses on invasive species. The other person who was very important for the growth of the network was Dr. Annie Simpson of the USGS, then in charge of the Global Invasive Species Information Network (GISIN), created to establish standards for invasive alien species data in order to facilitate the integration of databases at the global level and in several countries. The funds for the development of the national database were provided by the World Bank through the GEF for a project coordinated by the USGS that aimed to establish the InterAmerican Biodiversity Information Network (IABIN).',
    paragrafo1_1: '',
    paragrafo2: 'The I3N Network was active between 2001 and 2011, when the project ended. During that time, the database structure was distributed to 21 countries in the Americas along with training courses on invasive species management and database usage: Argentina, Brazil, Uruguay, Chile, Paraguay, Bolivia, Peru, Ecuador, Colombia, Venezuela, Surinam, The Dominican Republic, the Bahamas, Jamaica, St. Lucia, Panama, Costa Rica, Honduras, El Salvador, Nicaragua, and Guatemala.',
    paragrafo3: 'Unfortunately, the majority of the databases has been lost over time. The ones in operation since the start belong to Argentina (Universidad Nacional del Sur, managed by Dr. Sergio Zalba); Brazil (Instituto Hórus, managed by Dr. Sílvia Ziller and Dr. Michele de Sá Dechoum), Uruguay (Universidad de la República em Montevidéu, managed by Dr. Ernesto Brugnoli, later joined by the Ministry of Environment Invasive Alien Species Committee, M.Sc. Marcelo Iturburu), and Jamaica (Institute of Jamaica, managed by Dr. Suzanne Davis). Cooperation between these database managers has never ceased to exist.',
    paragrafo4: 'In the year 2011, the database was updated from the original format in Microsoft Access to open software, in MySQL. On the occasion, many corrections and improvements were implemented thanks to feedback from users in several countries. The new version was then distributed to all countries in the network.',
    paragrafo5: 'Ten years later, in 2021, the BioBridge Initiative of the Convention on Biological Diversity in Montreal, Canada, provided funds for another update in a project prepared by Argentina, Brazil, Uruguay and Paraguay, and in 2022 for establishing databases in Costa Rica, Honduras, and Chile, as well as for updating the Jamaica database. Many improvements were implemented in order to facilitate data management, data collection and export by users. We hope that this version is more accessible and more user-friendly, and that the National Databases provide information for management at all levels, scientific research, and technical support to managers in the field. We also hope that it helps people avert the use of destructive species and promotes the use of indigenous species, especially to safeguard native biodiversity and the resilience of natural ecosystems in the Americas.',
    paragrafo6: 'We are very grateful to all who have collaborated with the database in some way, by providing or validating information, by appending data or any other of the many forms of support we have received over time. The richness of data available is the result of a large, collective effort.',
    paragrafo7: ''
  },
  politicaDeDados: {
    cabecalhoTitulo: 'Data policy',
    titulo: 'Concepts and sources of reference',
    criterio: 'Criteria for adding new species',
    avaliacao: 'Data asssessment and review',
    validacao: 'Data validation',
    paragrafo1: 'The concepts used in this database are aligned with the concepts used in the International Convention on Biological Diversity:',
    marcador1: 'native species: species found within its native range, where it evolved and is part of a biological community;',
    marcador2: 'alien (non-native) species: species outside its known native range (past or present);',
    marcador3: 'invasive alien species: species that threaten habitats, ecosystems or other species, generally causing impacts and changes in natural environments.',
    paragrafo2: 'The Honduras Invasive Alien Species Database lists species that are present in Costa Rica and have a history of invasion in the country. Records of occurrence in Costa Rica are provided for all species. There may be exceptions in the case of species of imminent risk of introduction to the country, to support early detection and rapid response. Other lists may be developed for species that have been introduced, have a history of invasion elsewhere, but have not been observed in the wild in the country.',
    paragrafo3: 'The species contained in the database must not be considered equally invasive all over the country. They tend to invade certain habitats with higher compatibility or aggressiveness. For this reason, the habitat types each species invades are indicated, as well as places where they occur in the country. Species reported as invasive without geographic reference are not included until at least one or a few locations are confirmed.',
    paragrafo4: 'The Holdridge classification of life zones (Holdridge 1971) is used in the database for terrestrial habitats. The terms used for marine and freshwater environments were defined with experts in the respective fields. The controlled vocabulary was adopted from the Global Invasive Species Database',
    paragrafo5_1: 'The main taxonomic references used are the Missouri Botanical Gardens ',
    paragrafo5_2: 'The Plant List',
    paragrafo5_3: ', and the United States Integrated Taxonomic Information System - ITIS ',
    paragrafo5_4: 'Other more specific databases are commonly used as well to verify and complement data, such as the World Register of Marine Species',
    paragrafo5_5: 'as well as the Global Invasive Species Database ',
    paragrafo5_6: 'and the CABI Invasise Species Compendium',
    paragrafo6: 'Species are classified in the following categories for every occurrence record:',
    marcador4: ': when a species is restrained to human use, such as lab experiments, or under cultivation or breeding, not being present in natural areas.',
    marcador5: ': when a species is found in a natural area, but has been planted, or was recently introduced, and no evidence of invasion or spread is available.',
    marcador6: ': when a species is found in natural areas and a population has formed locally, and there is no evidence of dispersal to other areas.',
    marcador7: ': when the species occurs in natural areas, has started to reproduce and spread beyond the point of introduction.',
    paragrafo7: 'A species may therefore be considered invasive in one location, while it is casually present in another, or simply contained in a third place.',
    paragrafo8: 'Data have been collected for the country since 2003 with support from hundreds of people, listed in the database as Collaborators. These data providers send articles, reports, or field data with occurrences of species that are then added to the database.',
    paragrafo8_en: 'All the information on species occurrences are always connected to the source, as the name of the data provider is included with each record as well as references of published materials. Other data are referenced in the descriptive fields. In case of doubt or need for more information on a point of occurrence, users can contact the person who sent or published the data. The link between data and source is meant to ensure that every contribution is properly acknowledged and cited when used by others.',
    paragrafo9_1: 'If you have data on invasive alien species to contribute to this database, please use the spreadsheet available from our website and send it to ',
    paragrafo9_2: ' or',
    paragrafo9_3: ' contact us',
    paragrafo10: 'For other issues or questions, please contact us as well! ',
    paragrafo11: 'The criteria for adding invasive alien species to the database are: (a) the species is present in Costa Rica and at least one or a few reliable records of occurrence are available; (b) the species has a history of invasion in Costa Rica or elsewhere, preferably in climatic conditions that would favor establishment in the country. There may be exceptions to species that have not been found invading in Costa Rica, but are considered of high risk to biodiversity, have been introduced and are so far contained (under cultivation or breeding, in captivity, in aquaria, laboratories, etc.), or to species present in neighboring countries with which Costa Rica maintains relevant commercial relations, which might facilitate introductions. No invasive species is added without one or more validated records of occurrence.',
    paragrafo12: 'The database covers invasive alien species in all biological groups with potential of impact on biological diversity and/or on natural areas. While the same species may generate negative impacts on the economy, on social and cultural values, or on human or animal health, the focus of this database is on species that impact natural ecosystems and biological diversity.',
    paragrafo13: 'Once a species is included in the database, complementary information is appended. New data is included as available from published materials, and new occurrences are added from collaborators, field observations by practitioners and protected area managers or rangers, and technical and scientific publications. The addition of geographic coordinates is compulsory for all occurrences.',
    paragrafo14: 'The data is continually reviewed and updated from publications or communication with collaborators. When species in the database are questioned as new data are available, for example on the native range including Costa Rica, expert advice is sought by the Scientific Committee in order to decide whether the species in question should be removed or not. A few species that were included from the first national survey conducted in Costa Rica were later excluded for lack of evidence on invasion or because data were insufficient to comply with the inclusion criteria established. This may happen, for example, when a non-native species is found in a natural area, but there is no evidence of invasion over time, or data are insufficient to confirm invasion potential in the country or in other places in the world; or still, when a species native range is uncertain, and DNA analyses show that it is a native species in the region. In all these cases, the data is stored in a standby list to avoid any losses, and the species is no longer shown in the database.',
    paragrafo15: 'The validation of data included in the database by data providers / collaborators is conducted by verifying the reliability of the species identification and of places of occurrence.',
    paragrafo16: 'Taxonomic validation',
    marcador8: 'Can the data provider (author of the publication or collector of the specimen) be considered an expert on the biological group to which the species belongs, or have equivalent experience?',
    marcador9: 'Is the occurrence documented in a photograph through which it is possible to identify of the organism with high certainty?',
    marcador10: 'Is the occurrence documented in an indexed and peer-reviewed publication or in a technical document prepared or reviewed by experts?',
    marcador11: 'Does the information correspond to a specimen deposited in a herbarium or museum?',
    marcador12: ' Does the occurrence correspond to a species that was already registered for the country?',
    marcador13: 'Is the taxonomy to which the group belongs stable and well-known?',
    marcador14: 'Do the characteristics of the species allow for a relatively simple identification?',
    paragrafo17: 'A positive answer to at least one of questions 1, 2, 3 or 4 leads directly to the recommendation to consider the data as validated. In case of answering negatively to these four questions, the validation will be obtained only with positive answers to the last three (5, 6 and 7).',
    paragrafo18: 'Geographic validation',
    marcador15: 'Does the data include geographic coordinates? Coordinates taken directly at the observation site as well as coordinates that correspond to a nearby geographic reference are considered (as indicated).',
    marcador16: 'Is there a description of the site that facilitates its location in the field?',
    marcador17: 'Is the occurrence documented by a photograph that allows for a reliable identification of the site?',
    marcador18: 'Do the geographic coordinates provided coincide with the location of the described and / or photographed site?',
    marcador19: 'The species is already cited for the country and, in that case, is the locality included within its known range of distribution or in a site that is compatible with the sites of known presence and with its dispersal capacity?',
    marcador20: 'Are the ecological conditions of the site, inferred from the location indicated, compatible with the general niche requirements of the species?',
    paragrafo19: 'The geographical location data are validated if:',
    marcador21: 'the answer to questions 1, 2 and/or 3, and 4 is positive;  ',
    marcador22: 'a positive or negative answer is assigned to question 1, a negative answer is given to questions 2 and 3, but questions 5 and 6 have positive answers. ',
    paragrafo20: 'The location will be considered not validated if: ',
    marcador23: 'the answer is positive to question 1 and questions 2 and/or 3, but negative to question 4; ',
    marcador24: 'negative or positive answer to question 1 and positive to one or none of questions 5 and 6.',
    paragrafo21: 'Finally, the data are considered validated if the validation analysis of the two criteria (taxonomy and location) is passed.'
  },
  comiteCientifico: {
    cabecalhoTitulo: 'Scientific committee',
    paragrafo1: 'The National Invasive Alien Species Database is supported by a network of collaborators who help validate information, share new publications and data. The network includes experts in environmental sciences and taxonomy, field practitioners and managers whose knowledge is invaluable for the existence of the database.',
    paragrafo2: 'Contribution by experts is formally acknowledged. A few experts were invited to participate in a Scientific Committee for the database. The role of the Scientific Committee is to provide support in data validation, in updating information, and helping to build and maintain the network of collaborators, which should lead to an increase in the amount and quality of data received for inclusion in the database.',
    paragrafo3: 'Members of the Scientific Committee Comitê Científico are appointed for a period of three years, which may be renewed, or a member replaced. The first Scientific Committee was formed in 2023, and is composed by:',
    marcador1: 'Lilian Ferrufino-Acosta, Biologist, M.Sc., Dr. Natural Resources, Professor at the Universidad Nacional Autónoma de Honduras, and Manager of the National Database;',
    marcador2: 'Katya Soler-Romero, M.Sc., PhD in Biological Sciences, Department of Botany, Institute of Biology, postdoctoral fellow at the National Autonomous University of Mexico;',
    marcador3: 'Rina Fabiola Díaz, Biologist, M.Sc. in Botany, Research Assistant Paul C. Standley Herbarium (EAP), Zamorano University;',
    marcador4: 'Mayra Núñez Vallecillos, Biologist, M.Sc., Doctorate student, Assistant to the Coral Reef Alliance, and Collaborator to the National Database;',
    marcador4b: 'Olvin Oyuela, Biologist, M.Sc., Technical Assistant at the Universidad Nacional Autónoma de Honduras, and Collaborator to the National Database;',
    marcador4c: 'Jorge Pérez, Biologist, M.Sc., Technical Assistant at the Universidad Nacional Autónoma de Honduras, and Collaborator to the National Database.',
    marcador4d: '',
    marcador4e: '',
    paragrafo4: 'Roles of the Scientific Committee:',
    marcador5: 'Make decisions on the inclusion of new species in the database;',
    marcador6: 'Make decisions on the exclusion of species for lack of data or data inconsistency;',
    marcador7: 'Support for data validation;',
    marcador8: 'Support for data search and revision;',
    marcador9: 'Support to look for and contact collaborators for data contributions and validation;',
    marcador10: 'Recommendations for database improvement;',
    marcador11: 'Support for continuous data improvement and updates.'
  },
  especiesInstitucional: {
    titulo: 'Species search',
    paragrafo1: 'Search here for detailed information about invasive and potentially invasive alien species present in the Republic of Honduras, the specialists who study them, the projects dedicated to understanding their behavior, their effects and control options, as well as the publications that are published, refer to them.',
    paragrafo2_1: 'If you have additional information, we invite you to collaborate to improve knowledge about this problem by helping to keep these databases complete and updated, ',
    paragrafo2_2: 'please contribute to update this database',
    verTutorial: 'Open tutorial',
    paragrafo3: 'Please use the following format to cite data from this system:',
    paragrafo4_1: 'Honduras National Invasive Alien Species Database. Institute of Honduras. Checked on DD/MM/AAA.',
    paragrafo4_2: ' Accessed on __/__/____ (date)',
    tutorialTitulo1: 'Please cite our database',
    tutorialDescricao1: 'Please use this citation format in publications.',
    tutorialTitulo2: 'Use the filters',
    tutorialDescricao2: 'You’ll find some options to filter contents here.',
    tutorialTitulo3: 'Use the filters: select data',
    tutorialDescricao3: 'Choose filters and combine data by place, biological group, use, taxonomy, etc.',
    tutorialTitulo4: 'Number of records',
    tutorialDescricao4: 'This is the number of invasive alien species in the database or resulting from your search using combined filters.',
    tutorialDescricao5: 'Here you can select the number of records you wish to view per page.',
    tutorialTitulo6: 'List of invasive alien species',
    tutorialDescricao6: 'List of all species or search results using filters. Click on the name of the species to see the specific data set.',
    tutorialTitulo6_1: 'Export data',
    tutorialDescricao6_1: 'Here you can filter data and choose the format to export.',
    tutorialDescricao7: 'Taxonomic classification of the species selected, common names, synonyms, and a picture when possible!',
    tutorialTitulo8: 'Ecology and use',
    tutorialDescricao8: 'Description, biology and ecology of the species selected, native range, type of habitats it invades, and economic uses.',
    tutorialTitulo9: 'Introduction and spread',
    tutorialDescricao9: 'How the species arrived, why it was introduced, and how it spreads to other areas.',
    tutorialTitulo10: 'Impacts',
    tutorialDescricao10: 'Environmental, economic, health, and cultural impacts. Classification of environmental impacts according to the IUCN EICAT protocol.',
    tutorialTitulo11: 'Management',
    tutorialDescricao11: 'Prevention and early detection measures, mechanical, chemical, biological control.',
    tutorialTitulo12: 'Risk analysis',
    tutorialDescricao12: 'Risk analysis results for the species selected.',
    tutorialTitulo13: 'Places of occurrence',
    tutorialDescricao13: 'Location, ecosystems, protected areas, states in the country where the species occurs, and stage of invasion – see the map!',
    tutorialDescricao14: 'List of references used to compile data on the species and other publications on the species.',
    tutorialTitulo15: 'Projects',
    tutorialDescricao15: 'List of research and management projects on the species selected.'
  },
  colaboradoresInstitucional: {
    titulo: 'Collaborators',
    paragrafo1: 'Contributors include those who contribute data to this information system, as well as those who have collected museum specimens or herbarium specimens or are authors of publications that were used as data sources. ',
    paragrafo2: '',
    paragrafo3: 'Please use the following format to cite data from this system:',
    paragrafo4_1: 'Honduras National Invasive Alien Species Database. Institute of Honduras. Checked on DD/MM/AAA.',
    paragrafo4_2: ' Accessed on __/__/____ (date)'
  },
  acuaculturaInstitucional: {
    titulo: 'Aquaculture',
    paragrafo1: 'Aquaculture is the main pathway of non-native fish introductions in Honduras. At least ten species in different families were introduced since 1954, nine for production purposes, and one for fishing.',
    paragrafo2: 'The country’s fish production is dominated by two species of tilapia, the Mozambique tilapia Oreochromis mossambicus and Nile tilapia Oreochromis niloticus.',
    paragrafo3: 'The first species has been recorded in two departments and one water course that feeds into the Pacific Ocean, while the second one has been recorded in 15 departments and 17 water courses, 12 of which end in the Atlantic Ocean and 5 in the Pacific.',
    paragrafo3b: '',
    paragrafo4: 'Tilapia - Oreochromis niloticus',
    paragrafo4b: 'Foto: Jorge Alberto Perez Antunez'
  },
  acuariofiliaInstitucional: {
    titulo: 'Aquarium trade',
    paragrafo1: 'The origin of the aquarium trade is not documented in Honduras, neither the introduction of the first ornamental fishes. Non-native fishes are now easily found in pet shops and pages on the web, with enthusiasts dedicated to buying and selling ornamental fishes in several cities in the country.',
    paragrafo2: 'The General Law of Fisheries and Aquaculture of Honduras does not include specific regulations on the introduction and distribution of ornamental fishes, so the fish trade is most often practiced illegally with exception to a few private aquaria which follow the necessary steps enforced by the National Agricultural Sanitary Measures Service and local Prefectures.',
    paragrafo3: 'The Ministerial Agreement 0740-2019 signed by the Secretary of Energy, Natural Resources, Environment, and Mines published an aquaculture categorization table on which aquaria are rated level 3 for environmental impact.',
    paragrafo4: '',
    marcador1: '',
    marcador2: '',
    marcador3: '',
    paragrafo5: 'Acara azul - Andinoacara pulcher',
    paragrafo5b: 'Photo: Gabriel González',
    paragrafo6: 'Betta, luchador de siam - Betta splendens',
    paragrafo6b: 'Photo: Gabriel González',
    paragrafo7: 'Pez dorado - Carassius auratus',
    paragrafo7b: 'Photo: Gabriel González',
    paragrafo8: 'Pez cebra - Danio rerio',
    paragrafo8b: 'Photo: Gabriel González',
    paragrafo9: 'Escalar, pez ángel - Pterophyllum scalare',
    paragrafo9b: 'Photo: Gabriel González',
    paragrafo10: 'Tetra cardenal - Paracheirodon axelrodi',
    paragrafo10b: 'Photo: Gabriel González',
    paragrafo11: 'Tilapia - Oreochromis sp',
    paragrafo11b: 'Photo: Gabriel González'
  },
  especiesMarinoCosterasInstitucional: {
    titulo: 'Coastal marine species',
    paragrafo1: 'Marine invasive species are difficult to detect and generally introduced by human activities such as the discharge of ballast water or biofouling on ship hulls. Marine currents are another vector of introduction, which facilitated the spread of the lionfish Pterois volitans, introduced as an aquarium fish in Florida, USA, from where it escaped and colonized the Caribbean Sea, reaching the Atlantic Ocean. Other species are introduced for lack of security measures in breeding facilities for shrimp or fishes.',
    paragrafo2: 'Invasive species in new habitats establish successfully due to favorable environmental conditions or to the absence of natural predators to control the populations. They may lead to ecological disruption by preying on indigenous species or competing for space or breeding grounds with local species and displacing or reducing native populations, therefore impacting biodiversity, ecosystems, and human wellbeing.',
    paragrafo3: '',
    paragrafo3b: '',
    paragrafo4: 'Corona de cristo - Euphorbia milii',
    paragrafo4b: 'Foto: Herbario TEFH',
    paragrafo5: 'Bastón del emperador - Etlingera elatior',
    paragrafo5b: 'Foto: Herbario TEFH',
    paragrafo6: 'Orquidea africana - Oeceoclades maculata',
    paragrafo6b: 'Foto: Herbario TEFH',
    paragrafo7: 'Llama del bosque - Spathodea campanulata',
    paragrafo7b: 'Foto: Herbario TEFH',
    paragrafo8: 'Llama del bosque - Spathodea campanulata',
    paragrafo8b: 'Foto: Herbario TEFH',
    paragrafo9: 'Almendra - Terminalia catappa',
    paragrafo9b: 'Foto: Herbario TEFH'
  },
  ornamentalesInstitucional: {
    titulo: 'Ornamental Plants',
    paragrafo1: 'There are approximately 700 non-native species in Honduras, of which 305 are casual and 427 established (Rojas-Sandoval et al., 2022), the latter representing 6.4% of the native flora. For decades, gardening and landscaping promoted species introductions. These species are used in home gardens, along city streets, in urban parks, squares, and visitor centers in protected areas, as well as elsewhere, with the objective of creating beautiful spaces, providing shade, food for wildlife, floral resources for insects, birds, and bats, and refuges for plants and animals.',
    paragrafo2: 'The introduction of invasive species allows them to escape these spaces and colonize natural areas, displacing indigenous species. Some examples are the African orchid Oeceoclades maculata and the snake plant Sansevieria trifasciata, present in some protected areas, as well as African tulip tree Spathodea campanulata, casuarina Casuarina equisetifolia, bamboo Bambusa vulgaris, rose balsam Impatiens balsamina and black-eyed-susan vine Thunbergia alata, widely distributed and invasive in the Neotropics.',
    paragrafo3: '',
    paragrafo4: 'Columbia livia - paloma europea',
    paragrafo4b: 'Photo: Eduardo Chacon-Madrigal',
    paragrafo5: 'Felis silvestris - Gato doméstico',
    paragrafo5b: 'Photo: Sakthibalan',
    paragrafo6: 'Rattus rattus - rata negra',
    paragrafo6b: 'Photo: CSIRO ScienceImage 10564',
    paragrafo7: ''
  },
  plantasForrajerasInstitucional: {
    titulo: 'Forage Plants',
    paragrafo1: 'Forage plants have been used in Honduras to feed cattle, domestic birds, goats, and pigs, among other animals, supporting domestic animal breeding. The majority of forage species belong to the families Poaceae, of grasses, and Fabaceae, of beans and soybeans.',
    paragrafo2: 'In Honduras, several public and private institutions, as well as not-for-profit organizations, have promoted the introduction of forage plants as animal feed. These fast-growing species are usually grasses adapted to dry climates, tolerant to wet soils, compete well with weeds, are palatable to cattle and highly nutritious.',
    paragrafo2a: 'Introduced forage species are established in open ecosystems or deforested areas in early stages of forest development.',
    paragrafo2b: 'Some forage species are also used to create live fences, to protect roadsides, form windbreaks, control erosion, as soil cover or to reclaim degraded areas, as green fertilizer, dye, for honey production, floral resources for pollinators, in agroforestry systems or for ornamental use, as in the case of pinto peanut Arachis pintoi, for medicinal use as the butterfly pea Clitoria ternatea and velvet bean Mucuna pruriens, or use as insecticide, as Jack bean Canavalia ensiformis.',
    paragrafo3: 'Pasto guinea - Megathyrsus maximus',
    paragrafo3b: 'Photo: Arles Garcia',
    paragrafo4: 'Pasto guinea - Megathyrsus maximus',
    paragrafo4b: 'Photo: Arles Garcia'
  },
  mascotesInstitucional: {
    titulo: 'Pets and ornamental fishes',
    paragrafo1: 'The pet trade is one of the main pathways of introduction of invasive alien species, given the high diversity of species and quantity of individuals. People buy reptiles, birds or fishes on websites or in specialized shops. Although most animals are kept in confinement, some end up released because the owners can no longer care for them, or escape.',
    paragrafo2: 'Once released in natural areas or urban parks, these animals can establish and invade, pass on diseases or parasites or compete with native animals, generating negative impacts on natural ecosystems. Animal species are exported as pets, but also as live food or for decoration in aquaria or terraria where pets are kept.',
    paragrafo3: '',
    paragrafo4: 'Carassius auratus - carpa',
    paragrafo4b: 'Photo: Felipe Aira',
    paragrafo5: 'Lonchura malacca - munia tricolor',
    paragrafo5b: 'Photo: Shino Jacob Koottanad',
    paragrafo6: 'Poecilia reticulata - Guppy',
    paragrafo6b: 'Photo: Vincent Eisfeld ',
    paragrafo7: 'Xiphophorus variatus - Plati variado',
    paragrafo7b: 'Photo: Marrabbio'
  },
  plantasOrnamentaisInstitucional: {
    titulo: 'Ornamental plants',
    paragrafo1: 'Ornamental plants are used for gardening and landscaping in urban areas, interior design and flower production. The ornamental plant trade is a growing economic activity of high monetary relevance around the globe. Due to globalization and high demand, the ornamental plant trade generates intense movement of plants between countries and regions. The introduction of such plants without proper screening may cause ecological damage. The introduction of ornamental plants is currently the main source of invasive alien plants in many countries.',
    paragrafo2: 'More than 50% of all plant species and 85% of alien invasive trees in Costa Rica were originally introduced as ornamentals. Ecological damage can be irreversible and generate economic losses. The development of legal regulations for the ornamental plant trade is currently a goal of sustainability policies in many countries.',
    paragrafo3: '',
    paragrafo4: 'Impatiens hawkeri - china',
    paragrafo4b: 'Photo: Eduardo Chacon Madrigal',
    paragrafo5: 'Spathodea campanulate - Llama del bosque',
    paragrafo5b: 'Photo: Eduardo Chacon Madrigal',
    paragrafo6: 'Thunbergia alata - ojo de poeta',
    paragrafo6b: 'Photo: Eduardo Chacon Madrigal'
  },
  projetosInstitucional: {
    titulo: 'Projects',
    paragrafo1: 'This section includes information about current or previous projects focused on the study and management of invasive and potentially invasive alien species present in Honduras.',
    paragrafo2_1: 'If you participate or participated in a project related to these species, please help to incorporate it by sending the corresponding information to',
    paragrafo2_2: 'Please contribute data so we can keep the database up to date.',
    paragrafo2_3: '',
    paragrafo3: 'Please use the following format to cite data from this system:',
    paragrafo4_1: 'Honduras National Invasive Alien Species Database. Institute of Honduras. Checked on DD/MM/AAA.',
    paragrafo4_2: 'Accessed on __/__/____ (date)'
  },
  referenciasInstitucional: {
    titulo: 'Search for references',
    paragrafo1: 'The publications and technical reports cited here were used to add information about the species present in the national territory, their behavior and effects. Some include data on management options for different species.',
    paragrafo2: 'If you are an author or know of publications on the subject that are not yet included in this section, please help to incorporate them by sending the corresponding citations to',
    paragrafo3: 'Contribute with data',
    paragrafo4: 'Please use the following format to cite data from this system:',
    paragrafo5_1: 'Honduras National Invasive Alien Species Database. Institute of Honduras. Checked on DD/MM/AAA.',
    paragrafo5_2: 'Accessed on __/__/____ (date)'
  },
  usoFlorestalInstitucional: {
    titulo: 'Forestry trees',
    paragrafo1: 'Approximately 56% of Honduras is covered by forests, with 18% dominated by conifer forests.',
    paragrafo2: 'Several species have been introduced for wood production due to rapid growth and for agroforestry systems, such as korina or limba Terminalia superba and African mahogany Khaya senegalensis. In the Lempira region, the Quesungual system, combining native tree species with introduced fruit trees, is used on steep slopes.',
    paragrafo3: 'Some forest plantation projects led by public and private institutions, as well as not-for-profit organizations, have proposed the use of non-native species. The introduction of invasive species tends to exclude species that are native and endemic to our natural ecosystems, and may also impact water resources as fast-growing species are high water consumers.',
    paragrafo4: 'Limba - Terminalia superba',
    paragrafo4b: 'Photo: Alchetron Free Social Encyclopedia'
  },
  contatoInstitucional: {
    cabecalhoTitulo: 'Contact',
    contato: 'Contact',
    p1: 'Fill all the fields in the form below:',
    nome: 'Name',
    email: 'E-mail',
    instituicao: 'Institution',
    mensagem: 'Message',
    enviar: 'Send'
  },
  colaboreDados: {
    cabecalhoTitulo: 'Collaborate with information',
    paragrafo1: 'Our database is kept up to date with support from experts and nature lovers throughout the national territory. Before being published, all information provided is validated according to criteria adopted at the regional level.',
    paragrafo2: 'You can provide data on species not yet listed in our database or on new places of occurrence by downloading our app (Invasoras AR) or sending an e-mail to',
    paragrafo2b: 'You can also send information on new publications or projects on biological invasions in Honduras. Data providers that contribute regularly may receive a username and password to upload information directly to the database.',
    paragrafo3: 'Help us face the challenge of invasive alien species based with complete, up-to-date and reliable information!',
    paragrafo4: 'Thank you!'
  }
}
