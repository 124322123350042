<template>
  <div>
    <InstitucionalHeaderSingle :title="`${$t('baseDadosNacional.cabecalhoTitulo') }`" :classProp="'header-single header-rede-nacional'" />
    <div class="container">
      <div class="row">
        <div class="col-sm col-lg-10 py-5">
          <h2 class="laranja mt-2 h3">{{ $t('baseDadosNacional.titulo') }}</h2>
          <p>{{ $t('baseDadosNacional.paragrafo1') }}</p>
          <p>{{ $t('baseDadosNacional.paragrafo2') }}</p>
          <p>{{ $t('baseDadosNacional.paragrafo3') }}</p>
          <p>{{ $t('baseDadosNacional.paragrafo4') }}</p>
          <p>{{ $t('baseDadosNacional.paragrafo5') }}</p>
          <p>{{ $t('baseDadosNacional.paragrafo6') }}</p>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InstitucionalHeaderSingle from '@/components/institucional/InstitucionalHeaderSingle'
export default {
  name: 'BaseDadosNacional',
  components: { InstitucionalHeaderSingle }
}
</script>
