<template>
  <div>
    <div class="header-single header-projeto">
      <div class="container  h-100">
        <div class="row align-items-end  h-100">
          <div class="col-sm ">
            <h1 class="align-bottom">{{ $t('projetosInstitucional.titulo') }}</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="container pt-5">
      <div class="row mb-4">
        <div class="col-md-4 p-4">
          <p>{{ $t('projetosInstitucional.paragrafo1') }}</p>
        </div>
        <div class="col-md-4 p-4">
          <p>{{ $t('projetosInstitucional.paragrafo2_1') }} <a href="mailto:lilian.ferrufino@unah.edu.hn">lilian.ferrufino@unah.edu.hn</a>.</p>
        </div>
        <div class="col-md-4 p-0">
          <div class="p-3 rounded-5 box-citacao">
            <p>{{ $t('projetosInstitucional.paragrafo3') }}</p>
            <p class="mb-0">{{ $t('projetosInstitucional.paragrafo4_1') }}<br><br></p>
            </div>
        </div>
      </div>
      <ProjetosForm v-if="viewItem" :idItem="projeto.project_id" :viewItem="true" :byInstitucional="true"/>
      <Projetos v-else :withPageTitle="false" :withOrderBy="true" :byInstitucional="true" @actionByInstitucional="actionByInstitucional"/>
      <div class="row mb-2 mb-xl-3">
        <div class="col-auto ml-auto text-right mt-n1">
          <button v-if="viewItem" @click="viewItem=false" class="btn bt-filtrar mt-1 mb-2">
            {{ $t('label.exibirListagem') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'driver.js/dist/driver.min.css'
import Projetos from '../../admin/projetos/Projetos.vue'
import ProjetosForm from '../../admin/projetos/ProjetosForm.vue'
export default {
  name: 'ProjetosInstitucional',
  components: { Projetos, ProjetosForm },
  data () {
    return {
      driver: null,
      viewItem: false,
      projeto: {}
    }
  },
  methods: {
    actionByInstitucional: function (item) {
      this.projeto = item
      this.viewItem = true
    }
  },
  created () {
    this.viewItem = false
  }
}
</script>
